.splashScreen {
    background-color: $primary-color;
    margin: 0 !important;
    padding: 0 !important;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    overflow: hidden;
    position: relative;
}

.img-fluid {
    position: absolute;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.cloudR1 {
    top: 20%;
    right: -30%;
    animation-name: slideInRight;
}

.cloudR2 {
    bottom: 8%;
    right: -15%;
    animation-name: slideInRight;
}

.cloudL1 {
    top: 5%;
    left: -20%;
    animation-name: slideInLeft;
}

.cloudL2 {
    bottom: 25%;
    left: -10%;
    animation-name: slideInLeft;
}

@keyframes slideInRight {
    to {
        right: 0;
        opacity: 1;
    }
}

@keyframes slideInLeft {
    to {
        left: 0;
        opacity: 1;
    }
}

.splashVideo {
    position: absolute;
    bottom: 0;
    width: 99%;
    height: 50vh;
    z-index: 5;
    border-radius: 50% 50% 0 0;
    overflow: hidden;
    opacity: 0;
    animation: slideInUpVideo 1s ease-out 1s forwards;
}

@keyframes slideInUpVideo {
    0% {
        transform: translateY(100%);
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.splashLogo {
    z-index: 4;
    // animation: slideUpWithVideo 1s ease-out 1s forwards; 
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes slideUpWithVideo {
    0% {
        transform: translateY(50%);
        opacity: 1;
    }
    100% {
        transform: translateY(-80%);
        opacity: 1;
    }
}

.cloudR1,
.cloudR2,
.cloudL1,
.cloudL2 {
    &:last-child {
        animation-delay: 5s;
    }
}

.buttonBox {
    padding: 20px;
}

.button {
    display: flex;
    text-transform: capitalize;
    align-self: center;
    margin: -100px 0px 0px 0px;
    padding: 8px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    border-radius: 20px;
    background-color: $primary-color;
    width: 100%;
}

.button:hover {
    background-color: $primary-color;
}
