.searchCityBox{
    padding: 15px 20px 15px 20px;
    background-color: $secondary-color; 
}

.stores-container{
    display: flex;
    flex: 74px;
    gap: 1rem;
    padding: 40px 1rem 30px 1rem;
    overflow-x: auto !important;
    user-select: none;
    // position: relative;
    // top: 64%;
    border: none;
    border-radius: 0px;
    background-color: $secondary-color; 
    figure{
        position: relative;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: left;
        background-color: #fff;
        gap: 5px;
        margin: 0;
        transition: transform .2s ease-in-out;
        text-align: left !important;
        cursor: pointer;
        width: 150px;
        & > figcaption{
            max-width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
            color: #262626;
        }
        .storeName{
            font-size: 18px;
            font-weight: 600;
            padding: 2px 0 0px 8px;
        }
        .storeDistance{
            font-size: 14px;
            padding: 0px 0 20px 8px;
        }
    }
    .borderStore{
        border: 2px solid #1E9CED;
    }
    picture {
        display: flex;
        justify-content: left;
        align-items: left;
        border: none;
    }
    .personal{
        background: none;
    }
    picture > img{
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
}

.address-container{
    // padding: 0 0 20px 0;
    background-color: $secondary-color;
    // background-color: #000;
    display: flex;
    .address{
        font-size: 22px;
        color: $primary-text-color;
        padding: 20px 10px 0 10px;
        display: flex;
        align-items: center;
        font-weight: 600;
    }
    .address2{
        font-size: 14px;
        color: $primary-text-color;
        padding: 0px 10px 0 40px;
        display: flex;
        align-items: center;
    }
    .button{
        background-color: #1E9CED;
        color: #fff;
        font-size: 20px;
        padding: 12px 0;
        border-radius: 900px;
        text-align: center;
        margin: 15px 20px;
        width: auto;
        display: flex;
        justify-content: center;
    }
}

.locateMeButton{
    position: absolute;
    background-color: #fff;
    left: 50%;
    z-index: 1000;
    margin-top: -1%;
    transform: translate(-50%, -50%);
    color: #1E9CED;
    display: flex;
    align-items: center;
    padding: 10px 20px 10px 20px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 900px;
    cursor: pointer;
}

@media screen and ( min-width: 768px ) {
    .locateMeButton{
        left: 50%;
        margin-top: 1%;
    }
    .address-container{
        padding: 0 0 20px 0;
        height: auto;
    }
}

.nearestTag {
    text-transform: uppercase;
    font-size: 11px;
    color: #fff;
    border-radius: 900px;
    background-color: #1E9CED;
    position: absolute;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
}

.grantButton{
    background-color: #1E9CED;
    color: #fff;
    font-size: 16px;
    padding: 4px 20px 6px 20px;
    border-radius: 900px;
    text-align: center;
    margin: 2px 0 0 0px;
}

.locationIcon{
    font-size: 24px;
    color: $primary-text-color;
    font-weight: 700;
    padding: 15px 10px 10px 10px;
}

.permissionTitle{
    font-size: 16px;
    color: $primary-text-color;
    font-weight: 700;
    padding: 16px 10px 10px 0px;
}

.titleDesc{
    font-size: 14px;
    color: $primary-text-color;
    padding: 5px 15px 15px 25px;
}

.addressSelectBox{
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 5px 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #c5c5c5;
}
