.deleteScreen{
    .account-delete-main {
        height: 100vh; 
        background-color: #1E9CED;
        display: flex;
        align-items: center;
        justify-content: center; 
        flex-direction: column;
    }
    
    .account-delete-box {
        background-color: #F5F5F5;
        padding: 2%; 
        border-radius: 12px; 
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
        width: 400px;
        margin-top: 20px; 
    }
    
    @media screen and ( min-width: 375px ) {
        .account-delete-main {
            height: 100vh; 
            background-color: #1E9CED;
            display: flex;
            align-items: center;
            justify-content: center; 
            flex-direction: column;
        }
        
        .account-delete-box {
            background-color: #F5F5F5;
            padding: 2%;
            border-radius: 12px; 
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
            width: 350px;
            margin-top: 20px; 
        }
    
    }
    
    @media screen and ( min-width: 600px ) {
        .account-delete-main {
            height: 100vh; 
            background-color: #1E9CED;
            display: flex;
            align-items: center;
            justify-content: center; 
            flex-direction: column;
        }
        
        .account-delete-box {
            background-color: #F5F5F5;
            padding: 2%; 
            border-radius: 12px; 
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
            width: 400px;
            margin-top: 20px; 
        }
    }
    
    @media screen and ( min-width: 768px ) {    
        .account-delete-main {
            height: 100vh;
            background-color:#1E9CED;
            display: flex;
            align-items: center;
            justify-content: center; 
            flex-direction: column;
        }
    
        .account-delete-box {
            background-color: #F5F5F5;
            padding: 2%; 
            border-radius: 12px; 
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
            width: 400px;
            margin-top: 20px; 
        }
    }
}