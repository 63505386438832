.authenticationScreen{
  background-color: #71BAE6;
  color: #fff;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  padding: 110px 0 4px 4px;

  .termsConditions{
    position: relative;
    margin-top: -40px;
  }
  .skipText{
    padding-top: 5px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 22px 0 0;
  }
  .changeNumberText{
    position: absolute;
    top: 0;
    left: 0;
    padding: 14px 0px 0 12px;
  }
  .progressBox{
    text-align: center;
    position: relative;
    top: 150px;
  }
}

.otpField {
  display: none;
  visibility: hidden;
  position: absolute;
  transform: translateY(-100%);
  transition: visibility 0.5s, transform 0.5s;
}

.showOtpField {
  display: block;
  visibility: visible;
  transform: translateY(0);
}

.buttonContainer {
  position: relative;
  top: -60px;
  transition: top 0.5s ease-in;
}

.showOtpField + .buttonContainer {
    position: relative;
    top: 0px;
}

.accountDetails{
  background-color: #71BAE6;
  min-height: 100vh;
  color: #fff;
  padding: 120px 0 4px 4px !important;
  
  .progressBox{
    text-align: center;
    position: relative;
    top: 50px;
    .skipText{
      padding-top: 45px;
    }
  }
}

@media screen and ( min-width: 992px) {
  .authenticationScreen{
    background-color: #71BAE6;
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    padding: 2%;
    border-radius: 12px;
    width: 450px;
    min-height: 400px;
    border: none !important;

    .imageLogo{
      height: 163px;
      width: 121.54px;
      padding: 0 0 15px 0;
    }

    .termsConditions{
      position: relative;
      margin-top: -40px;
    }

    .progressBox{
      text-align: center;
      position: relative;
      top: 150px;
      .skipText{
        padding-top: 45px;
      }
    }
  }
  .accountDetails{
    background-color: #71BAE6;
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    border-radius: 12px;
    padding: 20px 20px 4px 20px !important;
    width: 450px;
    min-height: 500px;
    border: none !important;
    
    .imageLogo{
      height: 163px;
      width: 121.54px;
      padding: 0 0 15px 0;
    }

    .progressBox{
      text-align: center;
      position: relative;
      top: 50px;
      .skipText{
        padding-top: 45px;
      }
    }
  }
}