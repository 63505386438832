.arrivingNowOrders{
    margin: 5px 0px 10px 10px;
    .arrivingNewOrdersContainer{
        display: flex;
        overflow-x: auto;
        width: 100%;
    }
    .arrivingNewOrdersContainer::-webkit-scrollbar{
        display: none;
    }
    .arrivingNowOrderBox{
        background-color: #E5FFDF;
        padding: 6px;
        width: 345px !important;
        border-radius: 30px;
        flex: 0 0 auto;
        margin: 0 6px 0 6px;
        cursor: pointer;
    }
    .timeBox{
        background-color: #3AB857;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
    }
    .detailsBox{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 0 6px;
    }
    .arrivingText{
        font-size: 10px;
        font-weight: 500;
    }
    .minutes{
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
    }
    .orderNo{
        font-size: 14px;
        color: #186B8C;
        font-weight: 600;
    }
    .outletName{
        font-size: 10px;
    }
    .addressDetails{
        font-size: 10px;
        color: #9E9E9F;
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .arrow{
        font-size: 45px !important;
        color: #186B8C;
    }
}

@media screen and ( min-width: 992px ) {
    .arrivingNowOrders{
        margin: 5px 0px 10px 0px;
        .arrivingNewOrdersContainer{
            display: flex;
            flex-direction: column;
            overflow-x: auto;
            width: 100%;
        }
        .arrivingNowOrderBox{
            padding: 6px;
            width: 400px !important;
            flex: 0 0 auto;
            margin: 5px 6px 5px 6px;
        }
        .addressDetails{
            width: 220px;
        }
    }
}