.termsAndConditionScreen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $secondary-color;
}

.termsTitle {
    font-size: 18px !important;
    font-weight: bold !important;
    color: #186B8C;
    margin-top: 25px !important;
}

.termsContainer {
    background-color: #FFFFFF;
    margin: 10px 30px 30px 30px;
    padding: 28px;
    border-radius: 12px;
    overflow-y: auto;
    /* width: 100%; */
}

.agreement{
    font-size: 16px;
    color: #9F9F9F;
}

.termServices{
    font-size: 30px !important;
    font-weight: bold !important;
    color: #494949;
    margin-left: 10px;
}

.lastUpdated{
    font-size: 16px;
    font-weight: 500 !important;
    color: #7C7C7C;
    padding-bottom: 8px;
    border-bottom: 1px solid #D9D9D9;
}

.termsHeading{
    font-size: 20px !important;
    font-weight: bold !important;
    color: #494949;
    margin-top: 10px !important;
}

.termsDesc{
    font-size: 16px;
    color: #494949;
}