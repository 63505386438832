.savedAddressesScreen{
    background-color: $secondary-color;
    min-height: 100vh !important;
    padding: 25px 0 65px 0 !important;
    overflow-y: auto !important;
    .addAddressButton{
        display: flex;
        align-items: center;
        background-color: #1E9CED;
        color: #fff;
        margin: 18px 25% 20px 25%;
        justify-content: center;
        border-radius: 900px;
        padding: 7px 0 7px 0;
        cursor: pointer;
    }
    .addressBox{
        background-color: #fff;
        border-radius: 10px;
        padding: 10px;
    }
    .disabledAddress{
        background-color: #F4E4DE !important;
        border: 1px solid $primary-text-color;
    }
}

.drawerPaperForSavedAddress{
    border-radius: 0 !important;
    width: 400px !important;
    overflow: auto !important;
}

.drawerPaperForAddAddress{
    border-radius: 0 !important;
    width: auto !important;
    overflow: auto !important;
}

.addressModal{
    .address-container{
        padding: 10px 0 20px 0 !important;
        background-color: $secondary-color;
        display: flex;
        .address{
            font-size: 22px;
            color: $primary-text-color;
            padding: 0px 10px 0 10px;
            display: flex;
            align-items: center;
            font-weight: 600;
        }
        .address2{
            font-size: 14px;
            color: $primary-text-color;
            padding: 0px 10px 0 40px;
            display: flex;
            align-items: center;
        }
    }
    .enterDetailsTitle{
        font-size: 16px;
        color: $primary-text-color;
        font-weight: 700;
        padding: 12px 0 0 14px;
    }
    .formBox{
        margin: 5px 15px 32px 15px;
    }
    .textField{
        margin-top: 16px;
    }
    .addressChip{
        font-size: 14px;
        border: 2px solid #1E9CED;
        color: #1E9CED;
        border-radius: 900px;
        display: flex;
        align-items: center;
        margin: 16px 0 10px 0;
        padding: 4px 7px 4px 7px;
        width: 100%;
        justify-content: space-evenly;
        cursor: pointer;
    }
    .selected{
        color: #fff;
        background-color: #1E9CED;
    }
    .button{
        background-color: #1E9CED !important;
        color: #fff !important;
        font-size: 16px !important;
        padding: 12px 0 !important;
        border-radius: 900px;
        text-align: center;
        margin: 16px 0 0px 0!important;
        width: 100%;
    }
    .errorText{
        font-size: 8px;
        color: red;
    }
}

@media screen and ( min-width: 768px ) {
    .addressModal{
        width: 400px;
    }
}
