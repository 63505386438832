.couponsScreen{
    background-color: $secondary-color;
    min-height: 100vh;
    padding-bottom: 60px;
    .coupons-title {
        background-color: #fff;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
        padding: 10px 0 10px 0;
    }
    .couponHeading{
        display: flex;
        justify-content: center;
    }
    .headingText {
        color: $primary-text-color;
        padding-top: 10px;
        font-size: 16px !important;
        font-weight: bold !important;
    }
    .headingCart {
        color: #09A5F7;
        font-size: 12px !important;
        margin-left: 12px;
    }
    .couponApplyTo {
        color: $primary-color;
        font-size: 12px !important;
        font-weight: 600 !important;
    }
    .birthdayCoupon{
        .couponDiscountSection{
            // background-color: #B0B0B0;
            background-image: url('../../assets/images/couponDiscountBgBirthday.png');
            background-size: cover;
            background-position: center;
        }
        .couponOff {
            color: #B0B0B0;
            font-size: 14px !important;
        }
    }
    .notApplicable{
        .couponDiscountSection{
            // background-color: #B0B0B0;
            background-image: url('../../assets/images/couponDiscountBgInactive.png');
            background-size: cover;
            background-position: center;
        }
        .couponOff {
            color: #B0B0B0;
            font-size: 14px !important;
        }
    }
    .coupon {
        width: 100%;
        padding: 10px 0 10px 0;
    }
    .couponTag{
        font-size: 10px;
        color: crimson;
    }
    .bestTitle {
        color: $primary-text-color;
        font-weight: 500 !important;
        padding: 14px 0 8px 0;
    }
    .couponDetailSection{
        background-color: #FFFFFF;
        padding: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    .couponName {
        color: #3D3D4D;
        font-size: 20px !important;
        font-weight: 600 !important;
    }
    .couponOff {
        color: #009C64;
        font-size: 14px !important;
    }
    .couponDesc {
        color: #82838B;
        font-size: 14px !important;
        padding-top: 10px;
        margin-top: 5px;
        border-top: 1px dashed #82838B;
    }
    .couponTime {
        color: $primary-color;
        font-size: 14px !important;
        padding-top: 10px;
        margin-top: 5px;
        display: flex;
        align-items: center;
    }
    .validity{
        font-size: 12px;
        color: #C4C4C4;
        padding-top: 7px;
    }
    .validAndButton{
        display: flex;
        justify-content: space-between;
        padding-top: 7px;
    }
    .couponDetail .applyButton{
        border-radius: 20px;
        border: solid;
        width: 88px;
        padding: 0;
        margin-top: 3px;
    }
    .couponDiscountSection{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        display: flex;
        align-items: center;
        background-image: url('../../assets/images/couponDiscountBg.png');
        background-size: cover;
        background-position: center;
    }
    .verticalFlatOff {
        color: #fff;
        padding: 15px;
        font-weight: bold!important;
        font-size: 20px !important;
        border-right: 1px dashed #fff;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
    }
    .couponContainer{
        margin: 20px;
    }
}

@media screen  and ( min-width: 1400px ) {
   .couponsScreen{
        min-height: 100vh;
        padding-bottom: 20px;
        .couponHeading{
            display: flex;
            justify-content: center;
        }
        .coupon {
            width: 100%;
            padding: 10px 0 10px 0;
        }
        .couponApplyTo {
            font-size: 12px !important;
        }
        .bestTitle {
            font-weight: 500 !important;
            padding: 14px 0 8px 0;
        }
        .couponDetailSection{
            background-color: #FFFFFF;
            padding: 10px;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .couponName {
            color: #3D3D4D;
            font-size: 20px !important;
            font-weight: 600 !important;
        }
        .couponOff {
            color: #009C64;
            font-size: 14px !important;
        }
        .couponDesc {
            color: #82838B;
            font-size: 14px !important;
            padding-top: 10px;
            margin-top: 5px;
            border-top: 1px dashed #82838B;
        }
        .couponTime {
            color: $primary-color;
            font-size: 14px !important;
            padding-top: 10px;
            margin-top: 5px;
            display: flex;
            align-items: center;
        }
        .validity{
            font-size: 12px;
            color: #C4C4C4;
            padding-top: 7px;
        }
        .validAndButton{
            display: flex;
            justify-content: space-between;
            padding-top: 7px;
        }
        .couponDetail .applyButton{
            border-radius: 20px;
            border: solid;
            width: 88px;
            padding: 0;
            margin-top: 3px;
        }
        .verticalFlatOff {
            color: #fff;
            padding: 15px;
            font-weight: bold!important;
            font-size: 20px !important;
            border-right: 1px dashed #fff;
            writing-mode: vertical-rl;
            transform: rotate(180deg);
        }
        .couponContainer{
            margin: 20px;
        }
    } 
}