.productDrawer{
    .imageBox{
        // background: linear-gradient(to bottom, #fff, #C7EAFF);
        background: transparent;
        position: relative;
        width: 125px;
        height: 125px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        margin-left: 20px;
        .image{
            position: absolute;
            width: 115px;
            height: 115px;
            top: -40px;
        }
    }
    .drawerVegAndFavourite{
        display: flex;
        align-items: center;
        margin: 18px 0 0 0;
        justify-content: space-between;
    }
    .vegNonveg{
        height: 16px;
        padding-left: 8px;
    }
    .favouriteMarked{
        font-size: 22px;
        color: #1DA1F2;
        padding-top: 0px;
        padding-right: 12px;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 10px;
    }
    .star{
        position: relative;
        top: 1px;
        font-size: 12px;
    }
    .productRating{
        position: relative;
        color: #6B6C6E;
        font-weight: 700 !important;
        font-size: 12px;
    }
    .ratingNos{
      font-size: 10px;
      font-weight: 500;
    }
    .productDetails{
        padding: 15px 0px 10px 0px;
        .productTag{
        color: $primary-text-color;
        font-size: 10px;
        padding: 0 0 0px 0;
        font-weight: 500;
        text-transform: capitalize;
        }
        .productName{
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
        }
        .productInfo{
        color: #B3B3B3;
        padding: 0px 0px 6px 0;
        font-size: 9px;
        text-transform: capitalize;
        }
    }
    .addRemoveItem{
      padding: 5px 5px 5px 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $primary-color;
      .text{
        font-size: 30px !important;
        padding: 0 5px 0 5px;
      }
    }
    .priceAndButton{
      padding: 10px 30px 10px 30px;
      display: flex !important;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .productPrice{
        font-size: 20px;
        padding: 0px 18px 0px 0px;
        color: #1DA1F2;
        font-weight: 500;
      }
      .productMrp{
        padding-left: 3px;
        font-size: 16px;
        color: #A6A6A6;
        text-decoration: line-through !important;
      }
      .productOff{
        font-size: 10px;
        border: 1px solid #1DA1F2;
        padding: 5px 7px 5px 7px;
        height: 16px;
        color: $primary-color;
        border-radius: 6px;
        border-bottom-left-radius: 0px;
      }
    }
    .divider {
      width: 2px;
      height: 18px;
      background-color: #fff;
      margin: 0 8px;
    }
    .d-button{
      background-color: $primary-color;
      color: #fff;
      text-align: center !important;
      border-radius: 80px;
      font-size: 16px;
      cursor: pointer;
      justify-content: center;
      flex-shrink: 0;
      flex-grow: 1;
      display: flex;
      align-items: center;
      width: auto !important;
      padding: 12px 0px 12px 0px !important;
      margin: 0 40px 0 40px;
    }
    .exhaustedButton{
      background-color: #fff;
      color: #1DA1F2;
      border: 1px solid #1DA1F2;
      text-align: center;
      font-size: 12px;
      border-radius: 50px;
      padding: 10px 5px 10px 5px;
      cursor: not-allowed;
    }
    .addonsBox{
      padding: 10px 10px 0 10px;
      overflow-y: auto;
      max-height: 300px;
      scrollbar-width: thin;
      scrollbar-color: $primary-color #f0f0f0;
      background-color: #DFEEF4;
      .addonTitle{
        font-weight: 600;
        font-size: 14px;
        text-transform: capitalize;
      }
      .addonSelect{
        background-color: #E0E0E0 !important;
        color: #000;
        font-weight: 500;
        font-size: 10px;
        border-radius: 80px;
        padding: 6px 12px 6px 12px;
      cursor: pointer;
        text-transform: capitalize;
      }
      .addonValue{
        font-size: 10px;
      }
      .addonOutOfStock{
        font-size: 9px;
        color: #8E0606;
      }
      .addonItem{
        text-align: center;
        padding: 0 10px 0 10px;
        justify-content: right;
        display: flex;
      }
      .unselected{
        background-color: #fff !important;
        border: 1px solid $primary-color !important;
        color: #424345 !important;
      }
      .selected{
        background-color: #00A7FB !important;
        color: #fff;
        display: flex;
        align-items: center;
      }
      .addonItem .outOfStock{
        background-color: #E0E0E0 !important;
        color: #fff;
      }
    }
}

.MuiDrawer-paper {
  overflow: visible !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

@media screen and ( min-width: 380px ) {
  .productDrawer{
      .imageBox{
        width: 150px;
        height: 150px;
        .image{
          width: 155px;
          height: 155px;
          top: -40px;
        }
      }
      .vegNonveg{
        height: 25px;
      }
      .favouriteMarked{
        font-size: 30px;
      }
      .star{
        top: 1px;
        font-size: 16px;
      }
      .productRating{
        font-size: 16px;
      }
      .ratingNos{
        font-size: 14px;
      }
      .productDetails{
        padding: 21px 10px 10px 0px;
        .productTag{
          font-size: 12px;
          padding: 0 0 2px 0;
        }
        .productName{
          font-size: 18px;
        }
        .productInfo{
          padding: 2px 0px 10px 0;
          font-size: 11px;
        }
      }
      .addRemoveItem{
        padding: 10px 0px 10px 1px;
        .text{
          font-size: 35px !important;
          padding: 0 5px 0 5px;
        }
      }
      .priceAndButton{
        padding: 10px 30px 10px 30px;
        .productPrice{
          font-size: 30px;
          padding: 0px 18px 0px 0px;
        }
        .productMrp{
          padding-left: 3px;
          font-size: 21px;
        }
        .productOff{
          font-size: 14px;
          padding: 5px 7px 5px 7px;
          height: 23px;
        }
        
      }
      .d-button{
        font-size: 16px;
        margin: 0 60px 0 60px;
      }
      .exhaustedButton{
        font-size: 14px;
        padding: 10px 15px 10px 15px;
      }
      .addonsBox{
        padding: 10px 12px 0 12px;
        .addonTitle{
          font-size: 16px;
        }
        .addonSelect{
          font-size: 12px;
          padding: 6px 14px 6px 14px;
        }
        .addonValue{
          font-size: 11px;
        }
        .addonOutOfStock{
          font-size: 10px;
        }
        .addonItem{
          padding: 0 10px 0 10px;
        }
      }
  }
}

@media screen and ( min-width: 420px ) {
  .productDrawer{
      .imageBox{
        width: 150px;
        height: 150px;
        .image{
          width: 155px;
          height: 155px;
          top: -40px;
        }
      }
      .vegNonveg{
        height: 25px;
      }
      .favouriteMarked{
        font-size: 30px;
      }
      .star{
        top: 1px;
        font-size: 16px;
      }
      .productRating{
        font-size: 16px;
      }
      .ratingNos{
        font-size: 14px;
      }
      .productDetails{
        padding: 21px 10px 10px 0px;
        .productTag{
          font-size: 12px;
          padding: 0 0 2px 0;
        }
        .productName{
          font-size: 18px;
        }
        .productInfo{
          padding: 2px 0px 10px 0;
          font-size: 11px;
        }
      }
      .addRemoveItem{
        padding: 10px 0px 10px 1px;
        .text{
          font-size: 35px !important;
          padding: 0 5px 0 5px;
        }
      }
      .priceAndButton{
        padding: 10px 30px 10px 30px;
        .productPrice{
          font-size: 30px;
          padding: 0px 18px 0px 0px;
        }
        .productMrp{
          padding-left: 3px;
          font-size: 21px;
        }
        .productOff{
          font-size: 14px;
          padding: 5px 7px 5px 7px;
          height: 23px;
        }
        
      }
      .d-button{
        font-size: 20px;
        margin: 0 40px 0 40px;
      }
      .exhaustedButton{
        font-size: 14px;
        padding: 10px 15px 10px 15px;
      }
      .addonsBox{
        padding: 10px 15px 0 15px;
        .addonTitle{
          font-size: 16px;
        }
        .addonSelect{
          font-size: 12px;
          padding: 6px 14px 6px 14px;
        }
        .addonValue{
          font-size: 11px;
        }
        .addonOutOfStock{
          font-size: 10px;
        }
        .addonItem{
          padding: 0 10px 0 10px;
        }
      }
  }
}

@media screen and ( min-width: 992px ) {
  .productDrawer{
      min-height: 31vh;
      .imageBox{
      width: 170px;
      height: 170px;
      margin-left: 30px;
      .image{
          width: 165px;
          height: 165px;
          top: -40px;
      }
      }
      .vegNonveg{
        height: 22px;
      }
      .favouriteMarked{
        font-size: 30px;
      }
      .star{
        top: 2px;
        font-size: 16px;
      }
      .productRating{
        font-size: 16px;
      }
      .ratingNos{
        font-size: 14px;
      }
      .productDetails{
        padding: 15px 30px 10px 0px;
        .productTag{
            font-size: 12px;
            padding: 0 0 3px 0;
        }
        .productName{
            font-size: 20px;
        }
        .productInfo{
            padding: 0px 0px 5px 0;
            font-size: 12px;
        }
      }
      .addRemoveItem{
        padding: 10px 30px 10px 30px;
        .text{
          font-size: 35px !important;
        }
      }
      .d-button{
        font-size: 20px;
        margin: 0 20px 0 20px;
      }
      .exhaustedButton{
        font-size: 14px;
        // border-radius: 50px;
        padding: 10px 20px 10px 20px;
        // margin: 0 20px 20px 20px;
      }
      .priceAndButton{
        padding: 10px 0px 10px 0px;
        .productPrice{
          font-size: 30px;
          padding: 0px 18px 0px 0px;
        }
        .productMrp{
          padding-left: 3px;
          font-size: 21px;
        }
        .productOff{
          font-size: 14px;
          padding: 4px 7px 4px 7px;
        }
      }
      .addonsBox{
        padding: 10px 20px 0 20px;
      .addonTitle{
        font-size: 18px;
      }
      .addonSelect{
        font-size: 14px;
      }
      .addonValue{
        font-size: 13px;
      }
      .addonOutOfStock{
        font-size: 11px;
      }
      .addonItem{
        padding: 0 10px 0 10px;
      }
      }
  }

  .MuiDrawer-paper {
      display: flex !important;
      align-self: center !important;
      justify-self: center !important;
      width: 65vh;
  }
}

@media screen and ( min-width: 1400px ) {
    .productDrawer{
        min-height: 32vh;
        .imageBox{
          width: 150px;
          height: 150px;
          .image{
            width: 150px;
            height: 150px;
            top: -40px;
          }
        }
        .vegNonveg{
          height: 20px;
          margin-top: 2px;
        }
        .favouriteMarked{
          font-size: 30px;
          padding-top: 5px;
        }
        .star{
            top: 1px;
            font-size: 14px;
        }
        .productRating{
            font-size: 16px;
        }
        .ratingNos{
            font-size: 14px;
        }
        .productDetails{
            padding: 20px 30px 10px 0px;
            .productTag{
                font-size: 12px;
                padding: 0 0 0px 0;
            }
            .productName{
                font-size: 20px;
            }
            .productInfo{
                padding: 1px 0px 10px 0;
                font-size: 11px;
            }
        }
        .addRemoveItem{
            padding: 10px 30px 10px 30px;
            .text{
              font-size: 30px !important;
            }
        }
        .d-button{
          font-size: 18px;
          padding: 10px 0px 10px 0px;
          // margin-bottom: 25px;
        }
        .exhaustedButton{
            font-size: 14px;
            // border-radius: 50px;
            padding: 10px 20px 10px 20px;
            // margin: 0 20px 20px 30px;
        }
        .priceAndButton{
            padding: 10px 0px 10px 0px;
            .productPrice{
              font-size: 30px;
              padding: 0px 18px 0px 0px;
            }
            .productMrp{
              padding-left: 3px;
              font-size: 21px;
            }
            .productOff{
              font-size: 14px;
              padding: 4px 7px 4px 7px;
            }
        }
    }

    .MuiDrawer-paper {
      display: flex !important;
      align-self: center !important;
      justify-self: center !important;
      width: 65vh;
    }
}