.orderDetails{
    background-color: $secondary-color;
    min-height: 100vh;
    padding-bottom: 100px;
    .title{
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        padding: 15px 0 0px 0;
        color: $primary-text-color;
    }
    .qrBox {
        justify-content: center;
        text-align: center;
        height: 580px;
        margin: 0 15px 0 15px;
        background-image: url('../../assets/images/orderDetailBox.png');
        background-repeat: no-repeat;
        background-position: center;
        .successGif{
            height: 250px;
            width: 260px;
            margin-top: -10%;
        }
        .successTick{
            padding-top: 20px;
        }
        .scanText{
            font-size: 18px;
            font-weight: 600;
            position: relative;
            padding: 30px 0 0 0;
        }
        .orderId{
            padding: 0px 0 0 0;
        }
        .qrCode{
            height: 275px;
            width: 275px;
            padding: 10px 0 0 0;
        }
        .orderNo{
            padding: 0px 0 0 0;
            font-size: 24px;
            font-weight: 600;
        }
    }
    .orderFinalSummary{
        background-color: #fff;
        margin: 30px 20px 0px 20px;
        border-radius: 15px;
        padding-bottom: 5px;
        .couponBorder{
            position: relative;
            width: 100%;
            margin: -30px 0px 0px 0px;
        }
        .couponBorderInverted{
            position: relative;
            margin: 0px 0px -35px 0px;
        }
        .titleSecond{
            text-align: center;
            color: $primary-text-color;
            font-weight: 600;
            margin-top: -10px;
            padding-bottom: 10px;
        }
        .item{
            padding: 10px 0px 10px 0px;
        }
        .imageBox{
            background: linear-gradient(to bottom, #fff, #EF831E);
            position: relative;
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            border-radius: 50%;
            margin-top: -5px;
            .image{
                position:absolute;
                width: 60px;
                margin-top: -20px;
            }
        }
        .img-grid{
            padding: 20px;
        }
        .item-name{
            font-size: 16px;
            font-weight: 600;
        }
        .item-qty{
            font-weight: 600;
        }
        .item-cost{
            font-size: 16px;
            font-weight: 600;
            color: $primary-color;
        }
        .item-attribute{
            font-size: 12px;
        }
    }
    .orderSummarySecondBox{
        background-color: #fff;
        margin: 30px 20px 0px 20px;
        border-radius: 15px;
        padding-bottom: 5px;
    }
    .orderSummaryTable{
        padding: 20px 30px 30px 30px;
        .head{
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            padding-bottom: 10px;
            color: $primary-text-color;
        }
        .row{
            padding: 10px 0 10px 0;
            display: flex;
            justify-content: space-between;
        }
        .itemHead{
            font-size: 16px;
        }
        .itemValue{
            font-size: 16px;
        }
    }
    .savedBox{
        display: flex;
        justify-content: space-between;
        background-color: #57CE94;
        color: #fff;
        border-radius: 7px;
        padding: 5px 10px 5px 10px;
        margin: 0px 20px 0px 20px;
    }
    .paymentBox{
        padding: 20px;
        .head{
            text-align: center;
            font-size: 18px;
            border-top: 0.2px solid #707070;
            padding-top: 10px;
            font-weight: 600;
        }
        .payDetails{
            font-size: 16px;
            padding: 18px 0 0 0;
        }
        .button{
            background-color: $primary-color;
            color: #fff;
            cursor: pointer;
            border-radius: 900px;
            display: flex;
            justify-content: center;
            padding: 10px;
            margin: 18px 0 0 0;
            width: auto;
        }
    }
    .orderDeliveryDetail{
        margin: 0 18px 0px 18px;
        background-color: #fff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        position: relative;
        top: -10px;
    }
    .couponBorderInverted{
        position: relative;
        margin: 0px 0px -10px 0px;
    }
    .successGifDelivery{
        height: 90px;
        width: 90px;
        margin: -20px 10px 0 10px;
        z-index: 100;
    }
    .titleDelivery{
        color: $primary-text-color;
        font-weight: 600;
        font-size: 18px;
        padding: 10px 0 15px 0;
    }
}

@media screen and ( min-width: 992px ) {
    .orderDetailsModal{
        .orderDetails{
            background-color: $secondary-color;
            min-height: 50vh;
            width: 700px;
            padding-bottom: 0px;
            padding: 0 10px 18px 18px;
            border-radius: 12px;
            overflow: hidden;
            .title{
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                padding: 20px 0 18px 0;
            }
            .successGif{
                height: 130px;
                width: 130px;
                position: absolute;
                top: 0%;
                left: 9%;
            }
            .qrBox {
                text-align: center;
                height: 320px;
                background-image: url('../../assets/images/orderDetailBox.png');
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                margin: 40px 10px 0px 0px;
                .scanText{
                    font-size: 11px;
                    font-weight: 600;
                    padding: 15px 0 0 0;
                }
                .qrCode{
                    height: 170px;
                    width: 170px;
                    padding: 10px 0 0px 0;
                }
                .orderNo{
                    padding: 55px 0 0 0;
                    font-size: 20px !important;
                }
            }
            .orderItemsList{
                max-height: 220px !important;
            }
            .orderItemsList::-webkit-scrollbar {
                width: 8px;
            }
            .orderItemsList::-webkit-scrollbar-thumb {
                background-color: #888;
                border-radius: 4px;
            }
            .orderItemsList::-webkit-scrollbar-track {
                background-color: #f1f1f1;
            }
            .marginTop{
                margin-top: 25px !important;
            }
            .orderFinalSummary{
                background-color: #fff;
                margin: 20px 10px 0px 10px;
                border-radius: 15px;
                .couponBorder{
                    position: relative;
                    margin: -80px 0px 0px 0px;
                    width: 100%;
                    height: 20px;
                }
                .couponBorderInverted{
                    position: relative;
                    margin: 0px 0px -35px 0px;
                    width: 100%;
                    height: 20px;
                }
                .title{
                    text-align: center;
                }
                .item{
                    padding: 10px 10px 10px 10px;
                }
                .item-name{
                    font-size: 11px;
                }
                .item-cost{
                    font-size: 11px;
                    color: $primary-color;
                }
                .item-attribute{
                    font-size: 8px;
                }
            }
            .orderSummaryTable{
                background-color: #fff;
                padding: 10px 20px 10px 20px;
                border-radius: 15px;
                .head{
                    text-align: center;
                    font-size: 14px;
                    font-weight: 600;
                    padding-bottom: 10px;
                }
                .row{
                    padding: 4px 0 4px 0;
                    display: flex;
                    justify-content: space-between;
                }
                .itemHead{
                    font-size: 14px;
                }
                .itemValue{
                    font-size: 14px;
                }
            }
            .savedBox{
                display: flex;
                justify-content: space-between;
                background-color: #57CE94;
                color: #fff;
                border-radius: 7px;
                padding: 5px 10px 5px 10px;
                margin: 0px 10px 0px 10px;
                .text{
                    font-size: 10px;
                }
            }
            .paymentBox{
                padding: 8px;
                .head{
                    text-align: center;
                    font-size: 14px;
                    border-top: 1px solid #707070;
                    padding-top: 6px;
                    font-weight: 600;
                }
                .payDetails{
                    font-size: 10px !important;
                    padding: 8px 0 0 0;
                }
                .button{
                    background-color: $primary-color;
                    color: #fff;
                    text-align: center;
                    cursor: pointer;
                    border-radius: 7px;
                    padding: 5px;
                    margin: 12px 0 -3px 0;
                    font-size: 12px;
                }
            }
            .couponBorderInverted{
                position: relative;
                width: 100%;
                margin: 0px 0px -10px 0px;
            }
            .orderDeliveryDetail{
                width: 97%;
                margin: -14px 0px 0px 0px;
                background-color: #fff;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                position: relative;
                top: -10px;
            }
            .successGifDelivery{
                height: 70px;
            }
            .titleDelivery{
                font-size: 12px;
            }
            .placedTitle{
                color: $primary-text-color;
                font-weight: 700;
                text-align: right !important;
                font-size: 22px;
                padding: 8px 12px 0 0px;
            }
        }
        .addressBox{
            background-color: #fff;
            width: 90%;
            margin: 0 0 -28px 10px;
            border-radius: 15px;
            .icon{
                color: $primary-color;
                font-size: 14px;
                padding-right: 5px;
            }
            .text{
                font-size: 14px;
            }
            .firstBox{
                display: flex;
                justify-content: space-between;
                margin: 15px 0 0 0;
                padding: 10px 15px 10px 15px;
            }
            .time{
                font-size: 8px;
                padding-top: 5px;
            }
            .secondBox{
                display: flex;
                font-size: 8px;
                padding: 0 0 10px 18px;
            }
        }
    }
}

@media screen and ( min-width: 1200px ) {
    .orderDetailsModal{
        .orderDetails{
            width: 900px;
            padding-bottom: 0px;
            padding: 0 20px 18px 20px;
            .title{
                font-size: 16px;
                padding: 15px 0 20px 0;
            }
            .successGif{
                height: 180px;
                width: 180px;
                top: -6%;
                left: 8%;
            }
            .qrBox {
                height: 420px;
                margin: 20px 10px 10px 0px;
                .scanText{
                    font-size: 14px;
                    padding: 20px 0 0 0;
                }
                .qrCode{
                    height: 220px;
                    width: 220px;
                    padding: 15px 0 0px 0;
                }
                .orderNo{
                    padding: 84px 0 0 0;
                    font-size: 22px !important;
                }
            }
            .orderItemsList{
                max-height: 520px !important;
            }
            .orderFinalSummary{
                margin: 20px 10px 0px 10px;
                .couponBorder{
                    margin: -80px 0px 0px 0px;
                    height: 20px;
                }
                .couponBorderInverted{
                    margin: 0px 0px -35px 0px;
                    height: 20px;
                }
                .item{
                    padding: 10px 20px 10px 20px;
                }
                .item-name{
                    font-size: 14px;
                }
                .item-cost{
                    font-size: 14px;
                }
                .item-attribute{
                    font-size: 10px;
                }
            }
            .orderSummaryTable{
                padding: 10px 30px 0px 30px;
                .head{
                    font-size: 13px;
                    padding-bottom: 5px;
                }
                .row{
                    padding: 5px 0 5px 0;
                }
                .itemHead{
                    font-size: 12px;
                }
                .itemValue{
                    font-size: 12px;
                }
            }
            .savedBox{
                padding: 5px 10px 5px 10px;
                margin: 7px 20px 0px 20px;
                .text{
                    font-size: 14px;
                }
            }
            .paymentBox{
                padding: 20px 20px 10px 20px;
                .head{
                    font-size: 13px;
                    padding-top: 10px;
                }
                .payDetails{
                    font-size: 12px !important;
                    padding: 10px 0 0 0;
                }
                .button{
                    padding: 10px;
                    margin: 18px 0 0 0;
                    font-size: 12px;
                }
            }
            .placedTitle{
                font-size: 26px;
                padding: 12px 10px 0 0px;
            }
        }
        .addressBox{
            width: 93%;
            margin: 0 0 -28px 10px;
            .icon{
                font-size: 14px;
                padding-right: 5px;
            }
            .text{
                font-size: 14px;
            }
            .firstBox{
                margin: 15px 0 0 0;
                padding: 10px 15px 10px 15px;
            }
            .time{
                font-size: 10px;
                padding-top: 5px;
            }
            .secondBox{
                font-size: 10px;
                padding: 0 0 10px 18px;
            }
        }
    }
}

@media screen and ( min-width: 1400px ) {
    .orderDetailsModal{
        .orderDetails{
            width: 1140px;
            padding-bottom: 0px;
            padding: 0 20px 18px 20px;
            .title{
                font-size: 18px;
                padding: 15px 0 20px 0;
            }
            .successGif{
                height: 240px;
                width: 240px;
                top: -6.5%;
                left: 7.2%;
            }
            .qrBox {
                height: 550px;
                margin: 30px 10px 0px 0px;
                .scanText{
                    font-size: 18px;
                    padding: 30px 0 0 0;
                }
                .qrCode{
                    height: 280px;
                    width: 280px;
                    padding: 20px 0 0px 0;
                }
                .orderNo{
                    padding: 110px 0 0 0;
                    font-size: 31px !important;
                }
            }
            .orderItemsList{
                max-height: 320px !important;
            }
            .orderFinalSummary{
                margin: 20px 10px 0px 10px;
                .couponBorder{
                    margin: -80px 0px 0px 0px;
                    height: 20px;
                }
                .couponBorderInverted{
                    margin: 0px 0px -35px 0px;
                    height: 20px;
                }
                .item{
                    padding: 10px 20px 10px 20px;
                }
                .item-name{
                    font-size: 16px;
                }
                .item-cost{
                    font-size: 16px;
                }
                .item-attribute{
                    font-size: 12px;
                }
            }
            .orderSummaryTable{
                padding: 20px 30px 20px 30px;
                .head{
                    font-size: 16px;
                    padding-bottom: 10px;
                }
                .row{
                    padding: 5px 0 5px 0;
                }
                .itemHead{
                    font-size: 16px;
                }
                .itemValue{
                    font-size: 16px;
                }
            }
            .savedBox{
                padding: 5px 10px 5px 10px;
                margin: 0px 20px 0px 20px;
                .text{
                    font-size: 18px;
                }
            }
            .paymentBox{
                padding: 10px 20px;
                .head{
                    font-size: 18px;
                    padding-top: 8px;
                }
                .payDetails{
                    font-size: 16px !important;
                    padding: 5px 0 0 0;
                }
                .button{
                    padding: 10px;
                    margin: 8px 0 0 0;
                    font-size: 16px;
                }
            }
            .placedTitle{
                font-size: 30px;
                padding: 12px 14px 0 0px;
            }
            .orderDeliveryDetail{
                width: 97%;
                margin: -14px 0px 0px 0px;
                background-color: #fff;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                position: relative;
                top: -10px;
            }
            .successGifDelivery{
                height: 100px;
                width: 100px;
            }
            .titleDelivery{
                font-size: 18px;
            }
        }
        .addressBox{
            width: 95%;
            margin: 20px 0 -28px 10px !important;
            .icon{
                font-size: 18px;
                padding-right: 5px;
            }
            .text{
                font-size: 18px;
            }
            .firstBox{
                margin: 15px 0 0 0;
                padding: 10px 15px 10px 15px;
            }
            .time{
                font-size: 10px;
                padding-top: 5px;
            }
            .secondBox{
                font-size: 10px;
                padding: 0 0 10px 18px;
            }
        }
    }
}