/* BottomNav.css */
.bottomNav {
    background-color: $secondary-color !important;
    z-index: 999999 !important;
    width: 100% !important;
    
    .activeNav {
      position: relative;
      font-weight: bold;
      transition: color 0.3s ease;
      
      margin-top: -10px;
      height: 60px; 
      
      &::before {
        content: '';
        position: absolute;
        top: -4px;
        left: 50%;
        transform: translateX(-50%);
        width: 60px;
        height: 60px;
        background-color: $secondary-color;
        border-radius: 50%;
        z-index: -1;
      }
    }
  
    .inactiveNav {
      color: #6B6C6E;
      opacity: 0.7;
    }
  }
  