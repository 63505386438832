.pickUpCartComponent{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 20px;
    background-color: #fff;
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    .addressIcon{
      color: $primary-color;
    }
    .secondBox{
      text-align: right;
    }
    .storeAddress2{
      margin-left: 10px;
    }
}