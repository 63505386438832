.pickUpTimeComponent{
    background-color: $secondary-color;
    padding: 10px 5px 10px 5px;
    margin: 5px 15px 10px 15px;
    border-radius: 12px;
    .firstBox{
        display: flex;
        padding-bottom: 10px;
    }
    .secondBg{
        background-color: #FFF;
        padding: 10px 15px 15px 15px;
        border-radius: 12px;
    }
    .pickUpText{
        font-size: 20px;
        color: $primary-text-color;
        font-weight: 600;
        margin-left: 10px;
    }
    .readyTime{
        font-size: 18px;
        color: #424549;
        margin-top: 7px;
    }
    .availableSlot{
        font-size: 16px;
        margin-top: 7px;
    }
    .timeSlots{
        display: flex;
        justify-content: space-between;
        color: #424549;
        padding: 10px 0 0 0;
    }
    .time{
        background-color: #fff;
        border: 2px solid $secondary-color;
        padding: 5px;
        color: #424549;
        border-radius: 7px;
        cursor: pointer;
        font-size: 13px;
    }
    .active{
        color: #fff;
        background-color: $primary-color;
    }
}

@media screen and ( min-width: 420px) {
   .pickUpTimeComponent{
        padding: 10px 5px 10px 5px;
        margin: 5px 20px 10px 20px;
        border-radius: 12px;
        .firstBox{
            padding-bottom: 10px;
        }
        .secondBg{
            padding: 10px 15px 15px 15px;
            border-radius: 12px;
        }
        .pickUpText{
            font-size: 20px;
            margin-left: 10px;
        }
        .readyTime{
            font-size: 18px;
            margin-top: 7px;
        }
        .time{
            font-size: 16px;
        }
    } 
}

@media screen and ( min-width: 992px) {
    .pickUpTimeComponent{
        padding: 0px 5px 10px 5px;
        margin: 0px 0px 10px 0px;
        border-radius: 0px;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;        
        .firstBox{
            padding-bottom: 10px;
        }
        .secondBg{
            padding: 10px 15px 12px 15px;
            margin: 0 3px 0 3px;
        }
        .pickIcon{
            height: 16px;
            margin-left: 10px;
        }
        .pickUpText{
            font-size: 12px;
            margin-left: 10px;
        }
        .readyTime{
            font-size: 12px;
            margin-top: 2px;
        }
        .availableSlot{
            font-size: 14px;
            margin-top: 7px;
        }
        .timeSlots{
            padding: 5px 0 0 0;
        }
        .time{
            padding: 2px;
            font-size: 10px;
        }
    }
}

@media screen and ( min-width: 1200px) {
    .pickUpTimeComponent{
        padding: 10px 10px 20px 10px;
        margin: 0px 0px 0 0px;
        .secondBg{
            padding: 10px 15px 12px 15px;
            margin: 0 3px 0 3px;
        }
        .pickIcon{
            height: 23px;
        }
        .pickUpText{
            font-size: 18px;
        }
        .readyTime{
            font-size: 16px;
        }
        .availableSlot{
            font-size: 16px;
        }
        .time{
            margin: 2px;
            padding: 5px;
            font-size: 11px;
        }
    }
}

@media screen and ( min-width: 1400px) {
    .pickUpTimeComponent{
        margin: 0px 20px 0 20px;
        .time{
            font-size: 14px;
        }
    }
}