.membershipScreen{
    background-color: #49A6CF;
    overflow-y: auto;
    height: 100vh;
    .memberLevelBox{
        background-color: #49A6CF;
        display: flex;
        justify-content: center;
        align-items: center !important;
        .profile-img{
            width: 100% !important; 
            height: 200px !important;
            object-fit: contain !important;
            padding: 12px 0 12px 0;
        }
        .iconsForControl{
            height: 30px;
            width: 30px;
            margin: 0 10px 0 10px;
            cursor: pointer;
        }
    }
    .secondBox{
        background-color: $secondary-color;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .memberRewardsBox{
        text-align: center;
        margin: 0 20px 0 20px;
        .memberTitle{
            font-size: 16px;
            color: $primary-text-color;
            font-weight: 600;
            padding: 12px 0;
        }
    }
    .dot{
        padding-top: 12px;
        img{
            padding: 5px;
        }
    }
    .rewardCard{
        background-color: #fff;
        padding: 16px 18px 16px 18px;
        margin: 14px 0;
        border-radius: 6px;
        text-align: left;
        .rewardIcon{
            width: 100%;
            height: 70px;
            object-fit: cover;
        }
        .title{
            font-size: 20px;
            font-weight: 600;
        }
        .content{
            color: #009C64;
            font-size: 14px;
        }
    }
}

.membershipScreen::-webkit-scrollbar {
    // scrollbar-width: thin;
    display: none;
}

@media screen and ( min-width: 768px ) {    
    .membershipScreen::-webkit-scrollbar {
        scrollbar-width: thin;
        display: flex;
    }

    .membershipScreen {
        -ms-overflow-style: none !important; 
        scrollbar-width: thin !important;
    }
}

.membershipModal{
    width: 600px;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 60px 0 150px 0 !important;
    .membershipScreen{
        background-color: #49A6CF;
        .memberLevelBox{
            background-color: #49A6CF;
            text-align: center;
            display: flex;
            img{
                padding: 30px;
            }
            .iconsForControl{
                position: relative;
                margin: 37% 10px 0 10px;
                background-color: $secondary-color;
                border-radius: 50%;
                border: 2px solid #184375;
            }
        }
        .secondBox{
            background-color: $secondary-color;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
        .memberRewardsBox{
            height: auto;
            text-align: center;
        }
        .dot{
            img{
                padding: 5px;
            }
        }
    }
}