.storeHeader{
    background-color: $secondary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 8px 20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    .storeIcon{
        color: $primary-color;
        font-size: 20px;
        padding-right: 5px;
    }
    .profileImage{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        object-fit: cover;
    }
    // .storeAddress2{
    //     font-size: 12px;
    //     color: #9e9e9f;
    // }
    .storeAddress2{
        font-size: 10px;
        color: #9e9e9f;
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        margin-left: 0px;
        max-width: 160px;
    }
    .changeLocation{
        color: #393a3a;
        font-size: 12px;
    }
}