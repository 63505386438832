.orderHistory::-webkit-scrollbar {
    width: 0;
}

.orderHistory{
    background-color: $secondary-color;
    min-height: 100vh;
    padding-bottom: 10px;
    overflow: auto;

    .title{
        font-size: 20px;
        text-align: center;
        color: #451B6D;
        font-weight: 600;
        padding: 30px 0 10px 0;
    }

    .container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0px !important;
        padding: 0px !important;
    }
    .filter-button {
        background-color: #FFFFFF;
        color: #9B9B9B;
        border-color: transparent;
        border-radius: 20px;
        width: 80px;
        display: flex;
        align-items: center;
        justify-self: end !important;
        align-self: flex-end !important;
    }

    .filter-icon {
        font-size: 20px;
        margin-left: 16px;
    }

    .order-history-card {
        background-color: #FFFFFF;
        margin: 20px;
        border-radius: 6px;
        cursor: pointer;
    }

    .productsListing{
        border-top: 1px dashed #000;
        margin: 5px 10px 5px 10px;
        padding: 10px 0 10px 0;
    }

    .DateAndStatus{
        display: flex;
        justify-content: space-between;
        padding: 10px 10px 0px 10px;
    }

    .day-date{
        font-weight: 500;
    }

    .status{
        color: #CF0616;
        font-weight: 500;
        font-size: small;
    }

    .status-delivered{
        color: #00B612;
        font-weight: 500;
        font-size: small;
        display: flex;
        align-items: center;
    }

    .delivered-icon{
        width: 20px;
        height: 20px;
    }

    .rate{
        color: #82838B;
        padding-left: 10px;
    }

    .rupee{
        font-weight: 500;
    }

    .items{
        display: flex;
        padding: 10px 0px 10px 7px;
    }

    .buttonDiv{
        margin:  0 15px 15px 15px;
        display: flex;
        justify-content: space-between;
    }

    .reorderButton{
        background-color: #09A5F7 !important;
        border-color: transparent !important;
        color: #FFFFFF !important;
        font-weight: 500 !important;
        border-radius: 20px !important;
        width: 155px !important;
        margin-top: 4px !important;
        padding: 2% !important;
    }

    .rateorderButton{
        background-color: #FFFFFF !important;
        border: 1px solid #09A5F7 !important;
        color: #09A5F7 !important;
        font-weight: 500 !important;
        border-radius: 20px !important;
        width: 160px !important;
        margin-top: 4px !important;
        margin-left: 25px!important;
        padding: 2% !important;
    }
}