.emptyCart {
    background-color: $secondary-color !important;
    min-height: 100vh;
    text-align: center;
    font-family: $secondary-font !important;

    .birdImage {
        width: 50%;
        padding-bottom: 10px;
        margin: -20px 0 0 0;
    }

    .Box{
        margin: 0 10px 80px 10px;
        padding: 0 0 18px 0;
        border-radius: 16px;
        background-color: #fff;
    }

    .imageCategories {
        display: flex;
        flex-direction: column;
        margin: -10px 0 0px 0;

        .imageCategoryContainer {
            display: flex;
            overflow-x: auto;
            width: 100%;
            padding: 1px 2% -10px 2%;
        }

        .img-grid {
            flex: 0 0 auto;
            padding: 0 10px 0 10px;
            text-align: center;
        }

        .categoryName {
            font-size: 11px;
            font-weight: 400;
            font-family: $secondary-font;
            padding: 4px 0 0 0;
            cursor: pointer;
            text-transform: capitalize;
        }

        .imageBox {
            // background: linear-gradient(to bottom, #fff, #EF831E);
            position: relative;
            width: 58px;
            height: 58px;
            display: flex;
            justify-content: center;
            border-radius: 50%;
            margin-top: -5px;

            .image {
                position: absolute;
                width: 100% !important;
                // margin-top: -18px;
            }

            cursor: pointer;
        }
    }

    .imageCategories::-webkit-scrollbar {
        display: none !important;
    }

    .imageCategoryContainer::-webkit-scrollbar {
        display: none !important;
    }
    
}

@media screen and (min-width: 375px) {
    .imageCategories {
        .imageBox {
            width: 60px;
            height: 60px;
            margin-top: -5px;

            .image {
                width: 100%;
                // width: 40px;
                // margin-top: -18px;
            }
        }
    }
}

@media screen and (min-width: 420px) {
    .imageCategories {
        .img-grid {
            padding: 0 10px 0 10px;
        }

        .categoryName {
            font-size: 13px;
            padding: 6px 0 0 0;
        }
    }
}

@media screen and (min-width: 992px) {
    .imageCategories {
        .imageCategoryContainer {
            padding: 35px 2% 1px 12%;
        }

        .categoryName {
            font-size: 12px;
        }
    }
}