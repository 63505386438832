.sortByComponent{
    .sortBy{
        background-color: $secondary-color;
        color: #6B6C6E;
        padding: 3px 9px 3px 9px;
        border-radius: 16px;
        font-size: 12px;
    }
    .icons{
        height: 20px;
        margin: 0 5px 0 5px;
    }
    .filters{
        display: flex;
        justify-content: space-evenly;
        gap: 20px;
        padding: 0px;
        border-radius: 16px;
        background-color: $secondary-color;
    }
    .filterName{
        font-size: 11px;
        color: #6B6C6E;
        display: flex;
        align-items: center;
        transition: all 0.3s ease;
        padding: 2px;
    }
    .filterName:first-child, .filterName:last-child{
        padding: 2px 8px;
    }
    .filterActive{
        background-color: $primary-color;
        color: #fff;
        font-size: 11px;
        padding: 2px 6px;
        border: 2px solid $primary-color;
        border-radius: 20px;
        z-index: 1;
    }
}