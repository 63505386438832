.orderAgain{
    margin: 0px 0 0px 0;
    .orderContainer{
        display: flex;
        overflow-x: auto;
        width: 100%;
        align-content: baseline !important;
    }
    .orderContainer::-webkit-scrollbar {
        display: none;
    }
    .orderOff{
        background-color: $primary-color;
        position: relative;
        width: 50px;
        color: #fff;
        font-size: 12px;
        padding: 2px 5px 2px 5px;
        border-radius: 4px;
        margin: -20px 0 0 5px;
        border-bottom-left-radius: 0px;
    }
    .title{
        color: $primary-text-color;
        font-size: 17px;
        font-weight: 600;
        padding: 10px 0 10px 15px;
    }
    .orderBox{
        background-color: $secondary-color;
        flex: 0 0 auto;
        width: 200px !important;
        padding: 15px 10px 10px 10px;
        margin: 10px 10px 0px 10px;
        border-radius: 12px;
        height: max-content;
    }
    .orderItem{
        display: flex;
        align-items: center;
        padding: 5px 0 5px 0;
    }
    .orderNumber{
        background-color: $primary-text-color;
        color: #fff;
        font-weight: 500;
        padding: 6px;
        font-size: 10px;
        border-radius: 4px;
    }
    .orderName{
        padding: 0 0 0 5px;
        font-size: 13px;
    }
    .orderItemSummary{
        display: flex;
        align-items: center !important;
        justify-content: space-between;
        color: #fff;
        border-radius: 5px;
        background-color: $primary-color;
        margin-top: 7px;
        padding: 10px 7px 10px 7px;
    }
    .orderSummaryItems{
        border-right: 1px solid #fff;
        padding-right: 5px;
        margin-right: 5px;
        font-size: 10px;
    }
    .orderSummaryCost{
        font-size: 10px;
    }
    .orderAgainTitle{
        font-size: 10px;
    }
}