.wishlist{
    background-color: $secondary-color;
    min-height: 100vh;
    padding-top: 20px;
    overflow: auto;
    .title{
        text-align: center !important;
        color: $primary-text-color;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 600;
        margin-top: -25px;
    }
    .productsBox{
        background-color: #fff;
        padding-bottom: 25px;
        margin: 0 25px 0 25px;
        border-radius: 11px;
    }
    .product-item{
        padding-bottom: 25px;
    }
    .imageBox{
        // background: linear-gradient(to bottom, #fff, #C7EAFF);
        background: transparent;
        position: relative;
        width: 50%;
        height: 105px;
        top: 15px;
        left: 10px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        .image{
            position:absolute;
            width: 90px;
            margin-top: -15px;
        }
    }
    .ratingAndButton{
        display: flex;
        align-items: center;
        margin-top: 0px;
    }
    .typeAndFavouriteBox{
        width: 100%;
        align-items: center; 
    }
    .productName{
        font-size: 18px;
        padding: 8px 0px 10px 0px;
        // width: 140px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }
    .productOff{
        font-size: 10px;
        font-weight: 500;
        border: 1.5px solid #1DA1F2;
        padding: 3px 7px 3px 7px !important;
        color: $primary-color;
        border-radius: 4px;
        border-bottom-left-radius: 0px;
        height: 100% !important;
        display: flex !important;
        align-items: center;
        cursor: pointer;
        margin: 0 0 0 8px;
    }
    .notAddedBtn{
        background-color: $primary-color;
        color: #fff;
        text-align: center;
        width: 50px;
        font-size: 14px;
        position: relative;
        cursor: pointer;
        margin: 0px 0px -20px 0;
        border-radius: 50px;
        padding: 4px 12px 4px 12px;
    }
    .addedButton{
        background-color: $primary-color;
        color: #fff;
        text-align: center;
        width: 60px;
        font-size: 14px;
        position: relative;
        cursor: pointer;
        margin: 0px 0px -20px 0;
        border-radius: 50px;
        padding: 4px 12px 4px 12px;
    }
    .exhaustedBtn{
        background-color: #fff;
        color: #1DA1F2;
        text-align: center;
        width: 75px;
        font-size: 8px;
        position: relative;
        cursor: pointer;
        left: 16px;
        margin: 5px 0px -20px 0;
        border-radius: 50px;
        padding: 7px 8px 7px 8px;
        border: 1px solid #A8A8A8;
    }
    .favProduct {
        position: relative;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: #BDBDBD dotted;
    }
    .favProduct:last-child {
        border-bottom: none;
    }
    .favProductImg {
        height: 140px;
        width: 140px;
        position: absolute;
        z-index: 1;
    }
    .favVectorImg {
        position: relative;
        z-index: 0;
        margin-left: 8px;
        margin-top: 35px;
    }
    .favProductContent {
        width: 100%;
        padding: 12px 16px 10px 30px;
        display: flex;
        flex-direction: column;
    }
    .favProductIcon {
        display: flex;
        justify-content: space-between;
    }
    .vegAndOfferContainer {
        display: flex;
        align-items: center;
    }
    .bestOfferText {
        margin-left: 4px !important;
        color: #743900;
        font-size: 13px !important;
        text-transform: capitalize;
    }
    .favProductName {
        font-size: 18px;
        font-weight: 600 !important;
        word-wrap: break-word;
        max-width: 100%;
        padding-top: 6px;
        text-transform: capitalize;
    }
    .offerContain {
        margin-top: 5px;
        display: flex;
    }
    .offerPrice {
        font-size: 18px !important;
        color: #00A9FC;
    }
    .price {
        text-decoration: line-through;
        color: #B5B5B5;
        margin-left: 8px !important;
    }
    .rateAndButton {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .addButton {
        background-color: #00A9FC !important;
        color: #fff !important;
        border-radius: 20px !important;
        width: 100px;
    }
}

.wishlist::-webkit-scrollbar {
    width: 0;
}

.drawerPaperForWishlist {
    border-radius: 0 !important;
    width: 400px !important;
}