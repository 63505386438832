.cartItem{
    border: none !important;
    box-shadow: none !important;
    border-radius: 20px !important;
    padding: 2px 0 2px 0;
    .addRemoveItem{
        padding: 10px 0px 10px 20px;
        color: $primary-color;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .text{
            padding: 0px 6px;
            font-size: 25px !important;
        }
    }
    .productName{
        text-align: left !important;
        padding: 15px 10px 12px 20px;
        .name{
            font-weight: 600;
            font-size: 16px !important;
            line-height: 16px;
            text-transform: capitalize;
        }
        .addonText{
            font-size: 10px;
            font-weight: 500;
            text-transform: capitalize;
        }
    }
    .price{
        color: $primary-color;
        font-size: 16px;
    }
    .discountPrice{
        color: $primary-color;
        font-size: 16px;
        padding-right: 5px;
        .strikePrice{
            font-size: 12px;
            padding-right: 5px;
            color: gray !important;
            text-decoration: line-through;
        }
    }
    .outOfStock{
        text-align: right;
        color: #B7ACAC;
    }
    .removingText{
        text-align: right;
        color: #F21D1D;
    }
}

.exhausted{
    color: #B7ACAC;
}

@media screen and ( min-width: 425px ) {
    .cartItem{
        .productName{
            .addonText{
                font-size: 12px !important;
            }
        }
    }
}

@media screen and ( min-width: 992px ) {
    .cartItem{
        background-color: $secondary-color !important;
        .addRemoveItem{
            padding: 10px 0px 10px 25px;
            .text{
                padding: 0px 4px;
                font-size: 16px !important;
            }
        }
        .productName{
            padding: 12px 10px 10px 14px;
            .name{
                font-weight: 600;
                font-size: 14px !important;
            }
            .customize{
                font-size: 12px;
                padding-top: 2px;
            }
            .icon{
                top: -4px;
                font-size: 21px;
            }
        }
        .price{
            font-size: 14px;
        }
    }
}

@media screen and ( min-width: 1400px ) {
    .cartItem{
        .addRemoveItem{
            padding: 10px 0px 10px 30px;
            .text{
                padding: 0px 4px;
                font-size: 20px !important;
            }
        }
        .productName{
            padding: 12px 10px 10px 18px;
            .name{
                font-size: 16px !important;
            }
            .customize{
                font-size: 12px;
                padding-top: 2px;
            }
            .icon{
                top: -4px;
                font-size: 24px;
            }
        }
        .price{
            font-size: 16px;
        }
    }
}