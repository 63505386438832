.profileScreen{
    padding-bottom: 100px;
    overflow: auto;
    background-color: #49A6CF;
    .memberLevelImage {
        width: auto;
        height: 100px;
        cursor: pointer; 
        background-size: cover !important; 
        background-repeat: no-repeat;
        .profileImage{ 
            margin: 8px 0 0 10px;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
        }
        .xpanseCup{
            width: 14px;
            height: 22px;
            object-fit: cover;
            padding-left: 7px;
        }
        .points{
            font-size: 25px;
            font-weight: 700;
            color: $primary-text-color;
        }
        .pointsBox{
            position: relative;
            top: 30%;
            padding: 0 7px 0 7px;
            color: $primary-text-color;
        }
        .rewardText{
            font-size: 12px;
            // font-weight: 700;
            padding-left: 5px;
            color: $primary-text-color;
        }
    }
    .stickyTabsContainer {
        position: sticky !important;
        top: 0 !important;
        z-index: 999 !important;
        background-color: #fff; 
    }
    .stickyTabsContainer .tabTitle {
        color: #82C2EE;
    }
    .tabBody {
        width: 100%;
        min-height: 100vh;
        background-color: $secondary-color;
    }
    .listSection {
        background-color: $secondary-color;
        margin: 0;
        padding: 15px;
        color: $primary-text-color;
        list-style-type: none;
    }
    .greaterThanicon {
        font-size: 18px;
        font-weight: bold;
    }
    .listItems {
        margin-top: 10px;
        padding-bottom: 8px;
        border-bottom: #BDBDBD dotted;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        cursor: pointer !important;
    }
    .versionSection {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #989898;
    }
    .madeInSection {
        display: flex;
        align-content: center;
    }
    .logoutSection {
        margin-top: 60px !important;
    }
    .logout {
        text-decoration: none;
        color: $primary-text-color;
        font-size: 17px;
        margin: 10px;
        font-weight: bold;
        cursor: pointer;
    }
    .membershipCardBox{
        padding: 15px 15px 0 15px;
    }
    .shareButton{
        display: flex;
        background: linear-gradient(to right, #1DA1F2, #0D47A1);
        justify-content: space-between;
        padding: 10px 15px 10px 30px;
        border-radius: 99px;
        color: #fff;
        .shareIcon{
            width: 15% !important;
        }
    }
}

.profileScreen::-webkit-scrollbar {
    width: 0;
}

@media screen and ( min-width: 425px ) {
    .memberLevelImage {
        height: 200px !important;
        .profileImage{ 
            margin: 12px 0 0 10px;
            width: 70px;
            height: 70px;
        }
        .xpanseCup{
            width: 19px !important;
            height: 27px !important;
            padding-left: 7px;
        }
        .points{
            font-size: 36px !important;
        }
        .pointsBox{
            top: 35% !important;
            padding: 0 10px 0 10px !important;
        }
        .rewardText{
            font-size: 17px !important;
            padding-left: 5px;
        }
    }
    .membershipCardBox{
        padding: 15px 20px 0 20px;
    }
}

@media screen and ( min-width: 768px ) {
    .memberLevelImage {
        height: 205px !important;
    }
    .profileScreen{
        height: 100% !important;
        padding: 10px 10px 40px 10px !important;
        background-color: $secondary-color;
        .listSection {
            padding: 15px;
        }
        .greaterThanicon {
            font-size: 18px;
        }
        .listItems {
            margin-top: 10px;
            padding-bottom: 8px;
        }
        .versionSection {
            margin-top: 20px;
        }
        .logoutSection {
            margin-top: 10px !important;
        }
        .logout {
            font-size: 17px;
            margin: 10px;
            padding-bottom: 30px;
        }
    }
}