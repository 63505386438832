.privacyScreen{
    background-color: $secondary-color;
    min-height: 100vh;
    margin: 0% !important;
    padding: 20px;
    .privacyPolicyContainer {
        background-color: #FFFFFF;
        // margin: 0 20px 0px 20px;
        padding: 21px;
        border-radius: 12px;
        overflow-y: auto;
    }
    .privacyTitle {
        font-size: 18px !important;
        font-weight: bold !important;
        color: #186B8C;
        margin: 10px 0 10px 0 !important;
        text-align: center;
    }
    .lastUpdatedText {
        font-size: 15px !important;
        font-weight: 600 !important;
    }
    .aboutUsText {
        font-size: 15px !important;
        font-weight: 600 !important;
        margin-top: 10px !important;
    }
    .purposeText {
        font-size: 15px !important;
        font-weight: 600 !important;
        margin-top: 10px !important;
    }
    .consentText {
        font-size:  15px !important;
        font-weight: 600 !important;
        margin-top: 10px !important;
    }
    .confidentiallyText {
        font-size: 15px !important;
        margin-top: 10px !important;
        font-weight: 600 !important;
    }
    .voluntaryText {
        font-size: 15px !important;
        margin-top: 10px !important;
        font-weight: 600 !important;
    }
    .deletionText {
        font-size: 15px !important;
        font-weight: 600 !important;
        margin-top: 10px !important;
    }
    .privacyPolicyDesc {
        margin-top: 5px !important;
        font-size: 14px !important;
    }
}


