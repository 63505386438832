.paymentScreen{
    min-height: 100vh;
    background-color: $secondary-color;
    .head{
        color: #424549;
        font-size: 20px;
        margin: 25px 0 4px 30px;
    }
    .paymentTypeBox{
        display: flex;
        align-items: center;
        background-color: #fff;
        border-radius: 14px;
        margin: 0 30px 0 30px;
        padding: 17px 15px 17px 15px;
        .icon{
            color: $primary-color;
            font-size: 40px;
        }
        .textBox{
            padding: 0 0 0 7px;
        }
        .title{
            color: $primary-color;
            font-size: 18px;
        }
        .title2{
            font-size: 14px;
            margin: -4px 0 0 0;
        }
    }
}