.products-listing {
  display: flex;
  min-height: 100vh;
  padding-top: 0px;
  padding-bottom: 40px;
}

.products-listing::-webkit-scrollbar {
  display: none;
}

.categoryHeadImage{
  width: 100%;
  padding: 10px 10px 0px 10px;
  box-sizing: border-box;
}

.categoryTopImage{
  width: 106%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin: 25px 0 0 0;
}

.category-list {
  width: 20% !important;
  overflow-y: auto;
  background-color: $secondary-color;
  height: calc(100vh - 80px);
  margin: 80px 0 -30px 0;
  padding-top: 40px;
  border-top-right-radius: 50px;
  top: 80px;
  position: sticky !important;
  display: flex;
  flex-direction: column;
}

.category-list::-webkit-scrollbar {
  display: none;
}

.category-item {
  padding: 15px 10px 15px 10px;
  cursor: pointer;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  color: #424549;
}

.category-item-highlight-line.active{
  height: 50px;
  width: 7px;
  background-color: #1DA1F2;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.category-items-content {
  display: flex;
  align-items: center;
}

.category-item.active {
  color: #1DA1F2;
  font-weight: 500;
}

.products-container {
  width: 80%;
  // overflow-y: auto;
  background-color: #fff;
  padding: 0px 8px 0 8px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;   
  margin-bottom: 20px;
}

.products-container::-webkit-scrollbar {
  display: none;
}

.exhaustedBtnWishlist{
  background-color: #fff;
  color: #1DA1F2;
  text-align: center;
  width: 75px;
  font-size: 12px;
  position: relative;
  cursor: pointer;
  left: 16px;
  margin: 0px 0px -5px 10px;
  border-radius: 50px;
  padding: 7px 8px 7px 8px;
  border: 1px solid #A8A8A8;
}

.exhausted {
  .imageBox{
    // background: linear-gradient(to bottom, #fff, #D7D7D7) !important;
    background: transparent !important;
      .image{
        filter: saturate(0.4);
      }
  }
  .description{
    color: #B5B5B5;
    text-transform: capitalize;
  }
  .productName{
    color: #A8A8A8;
  }
  .productPrice{
    color: #A8A8A8;
  }
  .productMrp{
    color: #A8A8A8;
  }
  .productOff{
    color: #A8A8A8;
    border-color: #A8A8A8;
  }
  .productTag{
    color: #A8A8A8;
  }
  .veg-nonveg{
    filter: saturate(0.1);
  }
  .ratingAndButton{
    color: #A8A8A8;
  }
  .star{
    color: #A8A8A8;
  }
  .productRating{
    color: #A8A8A8;
    left: 5px;
  }
}

// Categories with image styling

.imageCategories{
  display: flex;
  margin: -10px 0 0px 0;
  .imageCategoryContainer {
    display: flex;
    overflow-x: auto;
    width: 100%;
    padding: 1px 2% -10px 2%;
  }
  .img-grid{
    flex: 0 0 auto;
    padding: 0 10px 0 10px;
    text-align: center;
  }
  .categoryName{
    font-size: 11px;
    font-weight: 500;
    padding: 4px 0 0 0;
    cursor: pointer;
    text-transform: capitalize;
  }
  .imageBox{
    // background: linear-gradient(to bottom, #fff, #EF831E);
    position: relative;
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    margin-top: -5px;
    .image{
      position:absolute;
      width: 100% !important;
      // margin-top: -18px;
    }
    cursor: pointer;
  }
}

.imageCategories::-webkit-scrollbar {
  display: none !important;
}

.imageCategoryContainer::-webkit-scrollbar {
  display: none !important;
}

@media screen and ( min-width: 375px ) {
  .imageCategories{
    .imageBox{
      width: 60px;
      height: 60px;
      margin-top: -5px;
      .image{
        width: 100%;
        // width: 40px;
        // margin-top: -18px;
      }
    }
  }
}

@media screen and ( min-width: 420px ) {
  .imageCategories{
    .img-grid{
      padding: 0 10px 0 10px;
    }
    .categoryName{
      font-size: 13px;
      padding: 6px 0 0 0;
    }
  }
}

@media screen and ( min-width: 992px ) {
  .imageCategories{
    .imageCategoryContainer {
      padding: 35px 2% 1px 12%;
    }
    .categoryName{
      font-size: 12px;
    }
  }
}
// Categories with image styling end

@media screen and ( min-width: 350px ) {
  .categoryTopImage{
    width: 100%;
    margin: 18px 0 -5px 0;
  }
}

@media screen and ( min-width: 380px ) {
  .categoryTopImage{
    width: 100%;
  }
}

@media screen and ( min-width: 992px ) {
  .products-listing {
    padding-top: 0px;
    position: relative;
    z-index: 1;
    padding-bottom: 0px;
  }

  .categoryTopImage{
    width: 100%;
    height: auto;
    margin: 25px 0 0 0;
    object-fit: cover;
  }

  .category-list {
    width: 12% !important;
    margin: 80px 0 -30px 0;
    padding-top: 40px;
  }

  .category-item {
    padding: 15px 10px 15px 10px;
    font-size: 12px;
  }

  .products-container {
    width: 88% !important;
    padding: 10px 28px 0 28px;
    // max-height: 100vh;
  }

  .MuiDrawer-paper {
    display: flex !important;
    align-self: center !important;
    justify-self: center !important;
    width: 65vh;
  }

  .product-listing-line {
    width: 650px;
    height: 22px;
    background-color: $secondary-color;
    position: fixed;
    top: 98%;
    z-index: 10;
    border-top-right-radius: 22px;
  }
}

@media screen and ( min-width: 1400px ) {
  .products-listing {
    position: relative;
    z-index: 1;
  }

  .categoryTopImage{
    width: 100%;
    margin: 25px 0 10px 0;
  }

  .category-list {
    width: 12% !important;
    margin: 80px 0 -30px 0;
    padding-top: 40px;
  }

  .category-item {
    padding: 15px 10px 15px 10px;
    font-size: 14px;
  }

  .products-container {
    width: 88% !important;
    padding: 10px 28px 0 28px;
    // max-height: 100vh;
  }

  .product-listing-curve {
    background-color: $secondary-color;
    height: 50px;
    width: 25px;
    margin-left: 121px;
    position: fixed;
    top: 92%;
    z-index: 10;
    display: flex;
    justify-content: end;
  }

  .product-listing-curve .product-listing-curve-inside-box {
    background-color: #fff;
    height: 46px;
    width: 50px;
    border-bottom-left-radius: 30px;
  }

  .product-listing-line {
    width: 1050px;
    height: 25px;
    background-color: $secondary-color;
    position: fixed;
    // top: 685px;
    top: 98%;
    z-index: 10;
    border-top-right-radius: 22px;
  }
}

@media screen and ( min-width: 1900px ) {
  .category-list {
    width: 12% !important;
    margin: 110px 0 -600px 0;
    padding-top: 80px;
  }

  .category-item {
    padding: 15px 10px 15px 10px;
    font-size: 16px;
  }

  .product-listing-curve {
    height: 60px;
    width: 25px;
    margin-left: 8%;
    top: 92.2%;
  }

  .product-listing-curve .product-listing-curve-inside-box {
    height: 56px;
    width: 50px;
  }

  .product-listing-line {
    width: 1250px;
    top: 98%;
  }

}