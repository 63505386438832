.couponBox{
    padding: 10px 20px 10px 20px;
    background-color: $secondary-color;
    .title{
        font-size: 20px;
        color: $primary-text-color;
        font-weight: 600;
    }
    .coupon{
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        border: 2px solid $primary-color;
        padding: 12px 10px 12px 18px;
        margin: 10px 0 10px 0;
        .code{
            font-size: 25px;
            color: $primary-color;
            font-weight: 600;
        }
        .saved{
            color: $primary-color;
            font-size: 17px;
        }
        .remove{
            font-size: 18px;
            color: #A2A7A9;
        }
    }
    .couponAdd{
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        border-radius: 202px;
        border: 2px solid $primary-color;
        padding: 12px 10px 12px 18px;
        margin: 10px 0 10px 0;
        text-align: center !important;
        .code{
            font-size: 25px;
            color: $primary-color;
            text-align: center !important;
            font-weight: 600;
        }
        .saved{
            color: $primary-color;
            font-size: 17px;
        }
        .remove{
            font-size: 18px;
            color: #A2A7A9;
        }
    }
    .bestApplied{
        font-size: 20px;
        color: $primary-color;
        font-weight: 500;
    }
}

@media screen and ( min-width: 992px ) {
    .couponBox{
        padding: 15px 10px 10px 10px;
        background-color: $secondary-color;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        .title{
            font-size: 14px;
        }
        .coupon{
            padding: 7px 6px 8px 14px;
            margin: 10px 0 10px 0;
            .code{
                font-size: 14px;
                text-align: center !important;
            }
            .saved{
                font-size: 16px;
            }
            .remove{
                font-size: 16px;
            }
        }
        .couponAdd{
            padding: 7px 6px 8px 14px;
            margin: 10px 0 10px 0;
            .code{
                font-size: 14px;
                text-align: center !important;
            }
            .saved{
                font-size: 16px;
            }
            .remove{
                font-size: 16px;
            }
        }
        .bestApplied{
            font-size: 19px;
        }
    }  
    .couponModal{
        background-color: $secondary-color;
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%, 50%);
        padding: 0%;
        width: 500px;
        height: 80%;
        border-radius: 12px;
        overflow: auto;
    }  
    .couponModal::-webkit-scrollbar {
        display: none;
    }
}

@media screen and ( min-width: 1400px ) {
    .couponBox{
        padding: 10px 15px 10px 15px;
        margin: 50px 20px 0 20px;
        .title{
            font-size: 20px;
        }
        .coupon{
            padding: 7px 6px 8px 14px;
            margin: 10px 0 10px 0;
            .code{
                font-size: 20px;
            }
            .saved{
                font-size: 16px;
            }
            .remove{
                font-size: 16px;
            }
        }
        .couponAdd{
            padding: 10px 6px 12px 14px;
            margin: 10px 0 10px 0;
            .code{
                font-size: 18px;
                text-align: center !important;
            }
            .saved{
                font-size: 16px;
            }
            .remove{
                font-size: 16px;
            }
        }
        .bestApplied{
            font-size: 19px;
        }
    }  
    .couponModal{
        background-color: $secondary-color;
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%, 50%);
        padding: 0%;
        width: 500px;
        height: 80%;
        border-radius: 12px;
        overflow: auto;
    }  
    .couponModal::-webkit-scrollbar {
        display: none;
    }
}
