.cartScreen{
    background-color: $secondary-color !important;
    min-height: 100vh !important;
    padding-bottom: 12%;
    .cartBox{
        box-shadow: none;
        position: relative;
        top: 30px;
        border-radius: 20px !important;
        padding: 1px 10px 1px 10px;
        margin: 0px 20px 50px 20px;
        height: auto;
        background-color: #fff;
    }
    .addMoreBox{
        border-top: 1px solid #E7E7E7;
        padding: 12px 20px 10px 15px;
        .title{
            font-weight: 400;
            font-size: 16px !important;
        }
        .addIcon{
            color: $primary-color;
        }
    }
    .orderSummaryTable{
        background-color: #fff;
        padding: 30px;
        .head{
            text-align: center;
            font-size: 18px;
            font-weight: 600;
            padding-bottom: 10px;
            color: $primary-text-color;
        }
        .row{
            padding: 10px 0 10px 0;
            display: flex;
            justify-content: space-between;
        }
        .itemHead{
            font-size: 16px;
        }
        .itemValue{
            font-size: 16px;
        }
        .savedBox{
            display: flex;
            justify-content: space-between;
            background-color: #57CE94;
            color: #fff;
            border-radius: 7px;
            padding: 5px 10px 5px 10px;
            margin: 10px 0px 0px 0px;
        }
    }
    .button{
        text-align: center !important;
        background-color: $primary-color;
        color: #fff;
        padding: 20px;
        border-radius: 300px;
        margin: 20px 0px 15px 0px;
        cursor: pointer;
        width: auto;
        display: flex;
        justify-content: center;
    }
    .cartDisabledBtn{
        background-color: #8F8F8F !important;
        cursor: not-allowed;
    }
}

.confetti {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999 !important;
}

.emptyCart{
    padding-top: 15%;
}

@media screen and ( min-width: 992px ) {
    .cartScreen{
        background-color: #fff !important;
        min-height: 100% !important;
        padding: 0 40px 0 0px;
        .desktopCartTitle{
            font-size: 20px;
            margin: 5px 0 0 10px;
            font-weight: 600;
            color: $primary-text-color;
        }
        .cartBox{
            top: 30px;
            padding: 1px 0 1px 0;
            margin: -15px 0px 40px 0px;
            background-color: $secondary-color;
        }
        .addMoreBox{
            padding: 12px 20px 10px 15px;
            .title{
                font-size: 14px !important;
            }
        }
        .orderSummaryTable{
            padding: 12px;
            padding-bottom: 15px;
            background-color: $secondary-color;
            margin: -10px 0px 0 0px;
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
            .orderSummaryBox{
                background-color: #fff;
                padding: 12px;
                border-radius: 12px;
            }
            .head{
                font-size: 14px;
                padding-bottom: 10px;
            }
            .row{
                padding: 6px 0 6px 0;
            }
            .itemHead{
                font-size: 12px;
            }
            .itemValue{
                font-size: 12px;
            }
        }
        .button{
            padding: 10px;
            margin: 10px 0px 0px 0px;
        }
    }
}

@media screen and ( min-width: 1400px ) {
    .cartScreen{
        padding: 0 30px 0 0px;
        .desktopCartTitle{
            font-size: 23px;
            margin: 5px 0 0 20px;
        }
        .cartBox{
            top: 30px;
            padding: 1px 0 1px 0;
            margin: -15px 20px 20px 20px;
        }
        .addMoreBox{
            padding: 12px 20px 10px 15px;
            .title{
                font-size: 14px !important;
            }
        }
        .orderSummaryTable{
            padding: 12px;
            padding-bottom: 21px;
            margin: -10px 20px 0 20px;
            .orderSummaryBox{
                padding: 20px;
            }
            .head{
                font-size: 16px;
                padding-bottom: 10px;
            }
            .row{
                padding: 6px 0 6px 0;
            }
            .itemHead{
                font-size: 14px;
            }
            .itemValue{
                font-size: 14px;
            }
        }
        .button{
            padding: 20px;
            margin: 10px 0px 0px 0px;
        }
    }
}

.paymentModal{
    min-width: 748px;
    background-color: $secondary-color;
    display: flex;
    .title{
        color: $primary-text-color;
        font-size: 23px;
        padding: 18px 0px 18px 25px;
    }
    .paymentMethods{
        background-color: #fff;
        margin: 20px;
        margin-top: 0px;
        border-radius: 7px;
    }
    .head{
        font-size: 14px;
    }
    .icon{
        font-size: 12px;
    }
    .typeBox{
        padding: 18px 0 18px 18px;
        cursor: pointer;
    }
    .selected {
        background-color: rgba(29, 161, 242, 0.5);
        border-radius: 7px;
    }
    .secondColumn{
        padding: 0px 20px 0 0;
    }
    .addressBox{
        background-color: #fff;
        width: 100%;
        border-radius: 7px;
        .icon{
            color: $primary-color;
            font-size: 18px;
            padding-right: 5px;
        }
        .text{
            font-size: 18px;
        }
        .firstBox{
            display: flex;
            justify-content: space-between;
            margin: 15px 0 0 0;
            padding: 10px 15px 10px 15px;
        }
        .time{
            font-size: 10px;
            padding-top: 5px;
        }
        .secondBox{
            display: flex;
            font-size: 10px;
            padding: 0 0 10px 18px;
        }
    }
    .cartTitle{
        display: flex;
        justify-content: space-between;
        padding: 18px 0 5px 0;
        .cart{
            color: $primary-text-color;
            font-size: 20px;
        }
        .items{
            font-size: 18px;
            color: #8F8F8F;
        }
    }
    .cartBox{
        padding: 2px 0 2px 0;
        margin: 0 0px 20px 0;
        .itemBox{
            margin: 10px 12px 8px 12px;
        }
        .productName{
            font-size: 12px;
        }
        .price{
            font-size: 10px;
            color: $primary-color;
        }
        .quantity{
            font-size: 10px;
            color: #B1B1B1;
            padding-left: 7px;
            padding-bottom: 5px;
        }
    }
    .button{
        text-align: center;
        background-color: $primary-color;
        color: #fff;
        padding: 20px;
        border-radius: 300px;
        margin: 0 0 15px 0;
        cursor: pointer;
        width: auto;
        display: flex;
        justify-content: center;
    }
}
