.scrollCouponComponent{
    padding-top: 12px;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;  
    .scrollTitle{
        color: $primary-text-color;
        font-weight: 600;
        padding-left: 16px;
    }
    
    .couponCount{
        background-color: $primary-color; 
        position: absolute; 
        top: 40%;
        right: 0; 
        padding-left: 14px;
        border-top-left-radius: 900px;
        border-bottom-left-radius: 900px;
    }
}

@media screen and ( min-width: 768px ) {
    .scrollCouponComponent{
        .scrollCouponContainer {
            scrollbar-width: thin;
            scrollbar-color: #1E9CED #fff !important;
            display: flex;
            overflow-y: hidden;
        }
        
        .scrollCouponContainer::-webkit-scrollbar {
            width: 8px;
        }
        
        .scrollCouponContainer::-webkit-scrollbar-track {
            background-color: rgba(11, 214, 177, 0.5) !important;
        }
        
        .scrollCouponContainer::-webkit-scrollbar-thumb {
            background-color: rgba(7, 7, 206, 0.5) !important;
            border-radius: 12px !important;
        }
    }
}