.storiesComponent{
  .title{
    font-size: 14px;
    padding: 20px 0 0 25px;
  }
  .stories-container{
    display: flex;
    flex: 74px;
    gap: 0.3rem;
    padding: 1rem;
    overflow-x: auto;
    user-select: none;
  }

  figure{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin: 0;
    transition: transform .2s ease-in-out;
    cursor: pointer;
    & > figcaption{
      max-width: 74px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      
      font-family: system-ui, sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      color: #262626;
    }
  }
  figure:hover{
    transform: scale(1.1);
  }
  picture {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
  }
  .personal{
    background: none;
  }
  picture > img{
    width: 64px;
    aspect-ratio: 1;
    object-fit: cover;
    border: 1px solid #b1b1b1;
    border-radius: 50%;
  }

  .fullscreen-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #b1b1b1;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .fullscreen-modal img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

}

