.faqScreen{
    background-color: $secondary-color;
    min-height: 100vh;
    .faqsTitle {
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-weight: bold !important;
        color: $primary-text-color;
        margin-top: 10px !important;
    }
    
    .accordions {
        margin: 20px;
    }
    
    .accordianTitle {
        font-size: 18px;
        font-weight: bold;
        color: #494949 !important;
    }
    
    .accordianDesc {
        font-size: 16px;
        color: #3C3C43;
    }
}   

