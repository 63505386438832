.floatingCart {
    background-color: $primary-color;
    position: fixed;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    padding: 10px;
    color: white;
    cursor: pointer;
    border-radius: 100px;
    width: 80%;
    padding: 10px 10px;
  }
  