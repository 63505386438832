/* :root {
    --bgColor: #f9f0e7;
}*/

/* General styles */
/* html, body {
    height: 100%;
    overflow: hidden;
} */



.supportMain {
    width: 100%;
    overflow: auto;
    height: 100%;
    background-color: #71BAE6;

}



@media screen and ( min-width: 768px ) {    
    
    .supportMain {
        width: 100%;
        overflow: auto;
        height: 100%;
        background-color: #71BAE6 !important;
    }
}