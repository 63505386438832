.reviewScreen{
    background-color: $secondary-color;
    min-height: 100vh;
    padding-bottom: 50px;
    .title{
        font-size: 18px;
        text-align: center;
        padding: 10px 0 10px 0;
        font-weight: 600;
        color: $primary-text-color;
    }
    .backIcon{
        position: absolute;
        top: 8px;
        color: $primary-text-color;
        font-size: 30px;
    }
    .productsBox{
        padding: 20px;
    }
    .orderTitle{
        font-size: 20px;
        color: #121A23;
        font-weight: bold;
        padding: 8px 0 5px 0px;
    }
    .itemBox{
        background-color: #fff;
        border-radius: 8px;
        padding: 30px 25px 30px 25px;
        margin: 12px 0 12px 0;
    }
    .imageBox{
        background: linear-gradient(to bottom, #fff, #EF831E);
        position: relative;
        width: 90px;
        height: 90px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        .image{
            position:absolute;
            width: 80px;
            margin-top: -18px;
        }
    }
    .productName{
        font-size: 16px;
        font-weight: 500;
        padding: 0 0 0 15px;
    }
    .customText{
        font-size: 11px;
        padding: 0 0 0 15px;
        color: #B3B3B3;
    }
    .starBox{
        padding: 7px 0 0 10px;
    }
    .star{
        padding: 0 5px 0 5px;
    }
    .sorryTitle{
        font-size: 16px;
        font-weight: 500;
        padding: 20px 0 12px 0;
    }
    .reviewBox{
        border-radius: 8px;
        width: 77vw; 
        border-color: #BEBEBE; 
        border-width: 1px; 
        border-style: solid;
    }
    .button{
        text-align: center !important;
        background-color: $primary-color;
        color: #fff;
        width: auto;
        border-radius: 100px;
        margin-top: 20px;
        font-size: 20px;
        padding: 13px 0 13px 0;
        display: flex;
        justify-content: center;
    }
}

@media screen and ( min-width: 992px ) {
    .reviewBox{
        border-radius: 8px;
        width: 23vw !important; 
        border-color: #BEBEBE; 
        border-width: 1px; 
        border-style: solid;
    }
}

.reviewModal {
    width: 450px;
}
