.pickupOutletScreen{
    background-color: $secondary-color;
    padding: 25px 15px 25px 15px;
    -ms-overflow-style: none !important; 
    scrollbar-width: thin !important;
    min-height: 100vh;
    .title{
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;
        // background-color: #fff;
        color: $primary-text-color;
    }
    .upcoming{
        background-color: #c6c6c6 !important;
        margin-top: 16px !important;
    }
    .pickupOutlet{
        border-radius: 12px;
        background-color: #fff;
        padding: 10px 10px;
        margin: 12px 0;
        .outletName{
            font-size: 14px;
            color: $primary-text-color;
            font-weight: 600;
        }
        .outletCity{
            font-size: 12px;
            color: $primary-text-color;
            font-weight: 500;
        }
        .outletAddress{
            font-size: 10px;
            color: $primary-text-color;
            font-weight: 400;
        }
        .openMapButton{
            background-color: #fff;
            color: $primary-color;
            position: relative;
            border: 1px solid $primary-color;
            border-radius: 900px;
            width: auto;
            padding: 5px 12px;
            font-size: 11px;
            margin: -5px 0 12px 0;
            cursor: pointer;
        }
        .selectStoreBtn{
            background-color: $primary-color;
            color: #fff;
            text-align: center;
            border-radius: 900px;
            padding: 8px 0;
            cursor: pointer;
        }
        .zomatoBtn{
            background-color: #fff;
            color: #fff;
            border: 1px solid black;
            text-align: center;
            border-radius: 900px;
            padding: 5px 0;
            cursor: pointer;
            margin-bottom: 6px;
        }
    }
}

.pickupOutletScreen::-webkit-scrollbar{
    scrollbar-width: thin !important;
    display: flex !important;
}

.hide-scrollbar::-webkit-scrollbar {
    display: flex;
}
  
.hide-scrollbar {
    scrollbar-width: thin;
}
  
.hide-scrollbar {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
}
  