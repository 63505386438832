.searchBar{
    padding: 10px 10px 10px 10px;
}

@media screen and ( min-width: 992px ) {
    .searchBar{
        padding: 10px 10px 10px 10px;
        width: 434px;
        .bar{
            background-color: #fff;
        }
    }
}

@media screen and ( min-width: 1400px ) {
    .searchBar{
        padding: 10px 0px 10px 0px;
        width: 534px;
    }
}

@media screen and ( min-width: 1600px ) {
    .searchBar{
        padding: 10px 10px 10px 10px;
        width: 654px;
    }
}

.drawerPaperForWishlist {
    .searchBar{
        padding: 10px 10px 10px 10px;
        width: auto;
    }
}