.editProfile{
    background-color: $secondary-color;
    min-height: 100vh;
    padding-top: 20px;
    overflow: auto;
    .title {
        padding-top: 16px;
        display: flex;
        justify-content: center;
    }
    .titleName {
        color: #451B6D;
        font-size: 16px;
        font-weight: bold;
    }
    .profile{
        display: flex;
    }
    .editIcon{
        position: relative;
        left: -25px;
        top: -13px;
        background-color: #71BAE6;
        color: #fff;
        font-size: 12px;
        border-radius: 50%;
        padding: 5px;
    }
    .profilePic{    
        margin-bottom: 10px;
        margin-top: -45px;
        height: 80px;
        width: 80px;
        border: 7px solid #fff;
        border-radius: 50%;
        object-fit: cover;
    }
    .profileDetails {
        display: flex;
        flex-direction: column;
        position: relative;
        top: -20px;
        margin-left: 10px;
    }
    .profileName{
        font-size: 20px;
        color: #451B6D;
    }
    .profileDate {
        font-size: 14px;
        color: #451B6D light;
    }
    .form {
        background-color: #fff;
        border-radius: 10px;
        margin: 20px;
        padding: 30px;
    }
    .textName {
        width: 310px;
        margin-bottom: 20px;
    }
    .fields{
        margin: 15px 0 15px 0;
    }
    .datePicker{
        padding: 1px;
    }
    .custom-datepicker {
        border: none;
        cursor: pointer;
        font-size: 12px !important;
    }
    .saveChangesButton{
        background-color: $primary-color;
        color: #fff !important;
        text-align: center;
        cursor: pointer;
        border-radius: 30px;
        width: 100%;
        padding: 14px;
        margin: 18px 0 0 0;
    }
    .saveChangesButton:hover{
        color: #451B6D;
        cursor: pointer;
    }
}

.drawerPaperForEditProfile {
    border-radius: 0 !important;
    width: 400px !important;
}

// .editProfile::-webkit-scrollbar {
//     width: 0;
// }

@media screen and ( min-width: 992px ) {
    .editProfile{
        background-color: $secondary-color;
        min-height: 100vh;
        padding: 0px 0 90px 0;
        width: 100%;
        .title {
            padding-top: 6px;
            display: flex;
            justify-content: center;
        }
        .titleName {
            color: #451B6D;
            font-size: 16px;
            font-weight: bold;
        }
        .profile{
            display: flex;
        }
        .editIcon{
            position: relative;
            left: -25px;
            top: -13px;
            background-color: #71BAE6;
            color: #fff;
            font-size: 12px;
            border-radius: 50%;
            padding: 5px;
        }
        .profilePic{    
            margin-bottom: 10px;
            margin-top: -45px;
            height: 80px;
            width: 80px;
            border: 7px solid #fff;
            border-radius: 50%;
            object-fit: cover;
        }
        .profileDetails {
            display: flex;
            flex-direction: column;
            position: relative;
            top: -20px;
            margin-left: 10px;
        }
        .profileName{
            font-size: 20px;
            color: #451B6D;
        }
        .profileDate {
            font-size: 14px;
            color: #451B6D light;
        }
        .form {
            background-color: #fff;
            border-radius: 10px;
            margin: 20px;
            padding: 30px;
        }
        .textName {
            width: 310px;
            margin-bottom: 20px;
        }
        .fields{
            margin: 15px 0 15px 0;
        }
        .saveChangesButton{
            background-color: $primary-color;
            color: #fff;
            text-align: center;
            cursor: pointer;
            border-radius: 30px;
            width: 100%;
            padding: 14px;
            margin: 18px 0 0 0;
        }
    }
}

@media screen and ( min-width: 1400px ) {
    .editProfile{
        padding: 10px 0 0px 0;
    }
}