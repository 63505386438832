.perfectCombo{
    margin: 5px 10px 10px 2%;
    .title{
        font-size: 20px;
        font-weight: 600;
        color: $primary-text-color;
        margin-left: 4px;
    }
    .perfectComboContainer{
        display: flex;
        overflow-x: auto;
        width: 100%;
    }
    .perfectComboContainer::-webkit-scrollbar {
        display: none;
    }
    .item{
        flex: 0 0 auto;
        background-color: #fff;
        width: 150px;
        margin: 12px 10px 10px 10px;
        padding: 10px 10px 10px 10px;
        border-radius: 12px;
    }
    .imageBox{
        // background: linear-gradient(to bottom, #fff, #EF831E);
        position: relative;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        margin-top: 12px;
        .image{
            position:absolute;
            width: 90px;
            margin-top: -6px;
        }
    }
    .productDetail{
        margin-top: -20px;
    }
    .productName{
        font-weight: 600;
        font-size: 18px;
        padding: 25px 0 5px 0;
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .offerPrice{
        color: $primary-color;
        font-size: 16px;
        padding-left: 5px;
    }
    .mrp{
        padding: 2px 0 0 5px;
        font-size: 13px;
        color: #B5B5B5;
        text-decoration: line-through;
    }
    .percentOff{
        color: $primary-color;
        border: 1px solid $primary-color;
        font-size: 12px;
        padding: 2px 4px 2px 4px;
        margin: 0 0px 0 10px;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
    }
    .quickAddBtn{
        background-color: $primary-color;
        display: flex;
        justify-self: center;
        align-self: center;
        color: #fff;
        justify-content: space-around;
        border-radius: 4px;
        margin: 12px 0 0 0;
        padding: 4px 8px 4px 8px;
        width: 120px !important;
    }
}