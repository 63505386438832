.welcomeScreen {
    background-color: $primary-color;
    height: 100vh;
    display: flex;
    align-items: center;
    
    .xpanseLogo{
        text-align: center;
    }
    .firstBox{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 20%;
    }
    .circularTextContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .welcomeScreenTypography {
            position: relative;
            font-size: 45px;
            letter-spacing: 8px;
            color: #fff;
            font-weight: 700;
        }

        .text-w {
            transform: translate(30, 6); 
            rotate: -28deg;
        }

        .text-e1 {
            transform: translateY(15); 
            rotate: -20deg;
            top: -15px;
        }

        .text-l {
            transform: translateY(5); 
            rotate: -10deg;
            top: -22px;
        }

        .text-c {
            transform: translateY(0); 
            rotate: 0deg;
            top: -25px;
        }

        .text-o {
            transform: translateY(5); 
            rotate: 10deg;
            top: -23px;
        }

        .text-m {
            transform: translateY(15); 
            rotate: 20deg;
            top: -12px;
        }

        .text-e2 {
            transform: translate(30, -6); 
            rotate: 28deg;
            top: 6px;
        }
    }
    .button{
        display: flex;
        text-transform: capitalize;
        align-self: center;
        margin: 25px 0px 0px 0px;
        padding: 8px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        border-radius: 25px;
        background-color: #71BAE6;
        width: 100%;
    }
    .button:hover{
        background-color: cadetblue;
    }

    .secondBox{
        .xpanseCoupon{
            .percentageeOff{
                position: absolute;
                font-size: 32px;
                top: 195px;
                left: 29px;
                color: #6C4298;
                z-index: 1000;
                font-weight: 600;
            }
            .couponCode{
                position: absolute;
                font-size: 22px;
                top: 330px;
                left: 29px;
                color: #fff;
                width: 100%;
                z-index: 1000;
                font-weight: 600;
            }
        }
    }
    
}

.xpanseCouponModal{
    .percentageeOff{
        position: absolute;
        font-size: 32px;
        top: 47%;
        left: 31%;
        color: #6C4298;
        z-index: 1000;
        font-weight: 600;
    }
    .couponCode{
        position: absolute;
        font-size: 19px;
        top: 74%;
        left: 0;
        color: #fff;
        width: 100%;
        z-index: 1000;
        font-weight: 600;
    }
}

.couponModalButton{
    display: flex;
    text-transform: capitalize;
    align-self: center;
    margin: 25px 0px 0px 0px;
    padding: 8px;
    text-align: center;
    color: #fff !important;
    font-size: 18px;
    border-radius: 25px !important;
    background-color: cadetblue !important;
    width: 60%;
    text-align: center !important;
}