.favouriteProducts{
    margin: 0 0px 0px 0;
    .title{
        color: $primary-text-color;
        font-size: 17px;
        font-weight: 600;
        padding: 25px 0 10px 15px;
    }
    .favouriteProductsContainer{
        display: flex;
        overflow-x: auto;
        width: 100%;
    }
    .favouriteProductsContainer::-webkit-scrollbar {
        display: none;
    }
    .imageBox{
        // background: linear-gradient(to bottom, #fff, #EF831E);
        background: transparent;
        position: relative;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        margin-top: -25px;
        .image{
            position:absolute;
            width: 65px;
            margin-top: -12px;
        }
    }
    .productBox{
        flex: 0 0 auto;
        background-color: #DFEEF4;
        width: 140px;
        margin: 30px 10px 10px 10px;
        padding: 10px 10px 10px 10px;
        border-radius: 12px;
        cursor: pointer;
        // z-index: -1;
    }
    .productDetail{
        margin-top: -20px;
    }
    .veg{
        position: relative;
        left: 80%;
        height: 20px;
    }
    .productName{
        font-size: 14px;
        padding: 0 0 5px 0;
        width: 140px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
    }
    .offerPrice{
        color: $primary-color;
        font-size: 14px;
    }
    .mrp{
        padding-left: 5px;
        font-size: 13px;
        color: #B5B5B5;
        text-decoration: line-through;
    }
    .percentOff{
        color: $primary-color;
        border: 1px solid $primary-color;
        font-size: 12px;
        padding: 2px 4px 2px 4px;
        margin: 0 0px 0 0;
        border-radius: 4px;
        border-bottom-left-radius: 0px;
    }
    .button{
        background-color: $primary-color;
        color: #fff;
        text-align: center;
        cursor: pointer;
        width: 50px;
        font-size: 16px;
        position: relative;
        left: 30px;
        margin: 5px 0px -20px 0;
        border-radius: 80px;
        padding: 4px 12px 4px 12px;
        display: flex;
        justify-content: center;
        // z-index: 1 !important;
    }
    .exhaustedButton{
        background-color: #A6A6A6;
        color: #fff;
        text-align: center;
        width: 70px;
        font-size: 12px;
        position: relative;
        cursor: not-allowed;
        z-index: 1000;
        left: 30px;
        margin: 5px 0px -20px 0;
        border-radius: 80px;
        padding: 8px 10px 8px 10px;
    }
}

@media screen and ( min-width: 992px ) {
    .favouriteProducts{
        margin: 0 0px 75px 0;
        .title{
            font-size: 16px;
            padding: 10px 0 10px 15px;
        }
        .favouriteProductsContainer{
            display: block;
            overflow-x: auto;
            width: 100%;
        }
        .imageBox{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-top: -25px;
            .image{
                width: 55px;
                margin-top: -7px;
            }
        }
        .productBox{
            width: 239px;
            margin: 30px 10px 30px 10px;
            padding: 10px 10px 15px 10px;
        }
        .productDetail{
            margin-top: -15px;
        }
        .veg{
            left: 90%;
        }
        .productName{
            width: 100%;
            font-size: 12px;
            padding: 0 0 5px 0;
        }
        .offerPrice{
            font-size: 12px;
        }
        .mrp{
            padding-left: 5px;
            font-size: 11px;
        }
        .percentOff{
            font-size: 10px;
            padding: 2px 4px 2px 4px;
            margin: 0 0px 0 12px;
        }
        .button{
            width: 40px;
            font-size: 13px;
            left: 75%;
            margin: -25px 0px 0px 0;
            padding: 4px 12px 4px 12px;
        }
        .exhaustedButton{
            width: 60px;
            font-size: 10px;
            left: 68%;
            margin: -25px 0px 0px 0;
            padding: 8px 10px 8px 10px;
        }
    }
}

@media screen and ( min-width: 1400px ) {
    .favouriteProducts{
        margin: 0 0px 70px 10px;
        .title{
            font-size: 17px;
            padding: 10px 0 0px 15px;
        }
        .imageBox{
            width: 60px;
            height: 60px;
            margin-top: -25px;
            .image{
                width: 70px;
                margin-top: -15px;
            }
        }
        .productBox{
            width: 339px;
            margin: 50px 10px 30px 10px;
            padding: 10px 10px 15px 10px;
        }
        .productDetail{
            margin-top: -20px;
        }
        .veg{
            left: 95%;
        }
        .productName{
            font-size: 14px;
            padding: 0 0 5px 0;
        }
        .offerPrice{
            font-size: 14px;
        }
        .mrp{
            padding-left: 5px;
            font-size: 13px;
        }
        .percentOff{
            font-size: 12px;
            padding: 2px 4px 2px 4px;
            margin: 0 0px 0 20px;
        }
        .button{
            width: 50px;
            font-size: 16px;
            left: 80%;
            margin: -25px 0px 0px 0;
            padding: 4px 12px 4px 12px;
        }
        .exhaustedButton{
            width: 70px;
            font-size: 12px;
            left: 75%;
            margin: -25px 0px 0px 0;
            padding: 8px 10px 8px 10px;
        }
    }
}