.navbar{
    background-color: $secondary-color;
    padding: 10px 25px 10px 30px;
    // .navLogo{
    //     height: 40px;
    // }
    .navbar-container{
        display: flex;
        justify-content: space-between;
    }
    .textBox{
        padding-left: 10px;
        .title{
            color: $primary-color;
            font-size: 18px;
        }
        .navTag{
            font-size: 12px;
        }
    }
    .storeHeader{
        display: flex;
        flex-direction: column;
        align-items: center;
        .storeCity{
            font-size: 14px;
            text-transform: capitalize;
        }
        .storeIcon{
            color: $primary-color;
            padding-right: 5px;
        }
        .profileImage{
            height: 50px;
        }
        .storeAddress2{
            font-size: 10px;
            color: #9e9e9f;
            position: relative;
            top: -12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            max-width: 100px;
            text-transform: capitalize;
        }
    }

    .loginBox{
        padding-top: 10px;
        .loginText{
            color: $primary-text-color;
            font-size: 12px;
            padding-right: 8px;
            cursor: pointer;
            text-transform: capitalize;
        }
        .profileImage{
            height: 40px !important;
            width: 40px !important;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    .procced {
        background-color: #1E9CED !important;
        color: white !important;
        border-radius: 18px !important;
        width: 291px;
        height: 44px;
        margin-top: 25px !important;
    }

    .lets {
        color: #fff;
        font-weight: 600;
        font-size: 26px;
        margin-bottom: 10px;
    }

    .otp {
        background-color: #71BAE6;
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%, 50%);
        padding: 2%;
        border-radius: 12px;
        width: 400px;
        height: auto;
    }

    .imageLogo {
        height: 163px;
        width: 121.54px;
    }

    @keyframes slideIn {
        0% {
            transform: translateY(-100%);
            opacity: 0;
        }

        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .otpfield {
        background-color: #fff;
        width: 290px !important;
        margin-top: 20px !important;
        animation: slideIn 1.0s ease forwards;
        border-radius: 12px;
    }

    .termsText {
        font-size: 10px;
    }

    .countDown {
        color: #EEEEEE;
        font-size: medium;
        text-decoration: underline;

    }

    .resend {
        color: #EEEEEE;
        font-size: medium;
    }

    .mobile-number-input {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;
        /* border-radius: 5px; */
        padding: 10px;
        background-color: #fff;
        border-radius: 10px;
        width: 280px;
    }

    .country-code {
        margin-right: 5px;
    }

    .phone-input {
        flex: 1;
        border: none;
        outline: none;
    }
}

.orderTypeBox{
    padding: 10px 0 20px 0;
    .title{
        color: $primary-text-color;
        font-size: 20px;
        padding: 0 0 0 15px;
    }
    .radioGroup{
        display: flex !important;
        justify-content: space-between !important;
    } 
}

.location{
    background-color: #fff;
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    padding: 0%;
    height: auto;
    width: 550px;
    border-radius: 12px;
}

.welcome{
    color: $primary-text-color;
    font-size: 16px;
}

.welcome2{
    font-weight: 500;
    font-size: 14px !important;
}

.changeLocation{
    color: $primary-text-color;
    font-weight: 600;
}

.detectButton{
    color: #fff !important;
    background-color: #1E9CED !important;
    border-radius: 8px !important;
    height: 45px;
    width: 300px;
    font-size: 12px !important;
    font-weight:400 !important;
}

.or{
    color: #6B6C6E;
    margin-left: 6px;
    margin-right: 8px;
}

.searchCity{
    padding: 0 !important;
    margin: 0 !important;
}

.outlet {
    background-color: $secondary-color;
    cursor: pointer;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.main{
    padding: 2%;
}

.pincode{
    color: $primary-text-color;
    padding: 5px 0 3px 15px;
    margin: 0 !important;
}

.mainCard {
    width: 150px;
    padding: 1%;
    margin: 2%;
    transition: transform 0.3s ease;
    flex: 0 0 auto;
    margin-right: 10px;
}

.mainCard.selected {
  transform: scale(1.1);
}

.cardImage{
    height: 100px;
}

.distance {
    font-size: 11px;
    margin: 0%;
    font-weight: 500;
}

.desc{
    margin: -10px;
}

.cardName{
    font-weight: 500 !important;
}

.cards-container::-webkit-scrollbar {
    width: 10px;
}

.cards-container::-webkit-scrollbar-thumb {
    background-color: #1E9CED;
    border-radius: 12px !important;
}

.cards-container{
    display: flex;
    overflow-x: auto;
    scrollbar-width: thin;
}

.cards-container::-webkit-scrollbar-thumb {
    background-color: #1E9CED;
    border-radius: 12px !important;
}

.cards {
    display: inline-block;
}

.drawerPaperForProfile {
    border-radius: 0 !important;
}

.custom-scrollbar {
  overflow-y: auto;
//   scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 18px;
  background-color: #9e9e9f !important;
  height: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #71BAE6 !important;
  border-radius: 4px;
}

@media screen and ( min-width: 768px ) {
    .navLogo{
        height: 25px;
    }
}

@media screen and ( min-width: 992px ) {
    .navLogo{
        height: 30px;
    }
}

@media screen and ( min-width: 1200px ) {
    .navbar{
        padding: 10px 40px 10px 50px;
        .textBox{
            padding-left: 10px;
            .title{
                font-size: 21px;
            }
            .navTag{
                font-size: 14px;
            }
        }
        .storeHeader{
            .storeCity{
                font-size: 16px;
            }
            .storeIcon{
                padding-right: 5px;
            }
            .profileImage{
                height: 50px;
            }
            .storeAddress2{
                font-size: 12px;
                top: -10px;
                max-width: 130px;
            }
        }
        .loginBox{
            padding-top: 0px;
            .loginText{
                font-size: 17px;
                padding-right: 8px;
            }
            .profileImage{
                height: 56px !important;
                width: 56px !important;
            }
        }
    }
    .navLogo{
        height: 40px;
    }
}

.sticky-header{
    top: 0;
    left: 0;
    right: 0;
    background-color: $secondary-color !important;
    .logo{
        .navLogo{
            height: 40px;
        }
        .textBox{
            padding-left: 10px;
        }
        color: #000 !important;
    }
}
