.deliveryAddressComponent{
    background-color: $secondary-color;
    padding: 10px 5px 10px 5px;
    margin: 5px 20px 10px 20px;
    border-radius: 12px;
    .firstBox{
        display: flex;
        padding-bottom: 10px;
        justify-content: space-between;
        align-items: center;
    }
    .secondBox{
        background-color: #FFF;
        padding: 10px 15px 15px 15px;
        border-radius: 12px;
    }
    .deliveryText{
        font-size: 20px;
        color: #424549;
        margin-left: 10px;
    }
    .contactDetails{
        font-size: 17px;
    }
    .address{
        font-size: 17px;
        font-weight: 700;
    }
    .changeBtn{
        color: #fff;
        background-color: #1E9CED;
        border-radius: 900px;
        font-size: 10px;
        display: flex;
        align-items: center;
        padding: 5px 12px 5px 12px;
        margin: 0px !important;
        height: 100%;
        cursor: pointer;
    }
}

@media screen and ( min-width: 992px) {
    .deliveryAddressComponent{
        background-color: $secondary-color;
        padding: 0px 10px 10px 10px;
        margin: 0px 0px 10px 0px;
        border-radius: 0px;
        .firstBox{
            padding-bottom: 10px;
        }
        .secondBox{
            padding: 10px 15px 15px 15px;
        }
        .deliveryText{
            font-size: 16px;
            margin: 4px 0 0 10px;
        }
        .contactDetails{
            font-size: 14px;
        }
        .address{
            font-size: 12px;
            font-weight: 700;
        }
        .changeBtn{
            padding: 5px 12px 5px 12px;
        }
    }
}


@media screen and ( min-width: 1200px) {
    .deliveryAddressComponent{
        .deliveryText{
            font-size: 18px;
            margin: 4px 0 0 10px;
        }
        .contactDetails{
            font-size: 16px;
        }
        .address{
            font-size: 14px;
            font-weight: 700;
        }
    }
}

@media screen and ( min-width: 1400px) {
    .deliveryAddressComponent{
        margin: 0px 20px 10px 20px;
        .deliveryText{
            font-size: 18px;
            margin: 4px 0 0 10px;
        }
        .contactDetails{
            font-size: 16px;
        }
        .address{
            font-size: 14px;
            font-weight: 700;
        }
    }
}