/* ProgressBar.css */

.progress-bar-container {
    width: 100%;
    margin: 30px 0 20px 0;
    .progress-bar {
        position: relative;
        width: 100%;
        height: 4px;
        background-color: #f0f0f0;
        border-radius: 10px;
    }
    
    .progress {
        height: 100%;
        background-color: $primary-text-color;
        border-radius: 10px;
    }
    
    .marker {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 50%;
    }
    
    .marker.filled {
        background-color: $primary-text-color;
        // border: 2px solid #fff;
    }    
}

