// @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

// $primary-font: 'Roboto', sans-serif !important;
// // $secondary-font: 'Open Sans', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

$primary-font: 'Roboto', sans-serif !important;
$secondary-font: "Roboto Condensed", sans-serif;

$primary-color: #09A5F7;
$secondary-color: #DFEEF4;
$primary-text-color: #0E4961; 
$green: #36CD1D;
$orange: #FB9000;
$properties: #D0EFFF;

$white: #fff;
$black: #000;

$sub-text: #6a6a6a;

body{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.img-fluid{
  max-width: 100%;
  height: auto;
}

.error-message{
  margin: 0;
  padding: 0;
  position: relative;
  top: -5px;
  color: red;
  font-size: 11px;
}

.pac-container {
  z-index: 10000 !important;
  position: fixed !important;
}

.errorText{
  margin-top: 3px !important;
  font-size: 12px !important;
}

.desktopThirdColumn{
  position: fixed;
  right: 0px;
  top: 10;
  width: 30%;
  height: 100%;
  overflow-y: auto;
}

.desktopThirdColumn::-webkit-scrollbar {
  display: none;
}
