.advertiseScreen{
    background-color: #fff;    
    // padding-bottom: 10px;
    overflow: auto;
    scrollbar-width: none; /* Firefox */
    scrollbar-color: #cccccc #f1f1f1;
    min-height: 100vh;
    .slider-container {
        overflow: hidden;
        display: flex;
        width: 100%;
    }
    .slide {
        min-width: 100%;
        transition: transform 0.5s ease-in-out;
        display: flex;
        justify-content: center;
    }
    .homeMain {
        width: 100%;
    }
    .curved-image {
        overflow: hidden;
        width: 100%;
    }
    .homeBannerImage {
        width: 100%;
        height: 300px;
        object-fit: cover;
        display: block;
        border: -50px !important;
        -webkit-mask-image: radial-gradient(110% 50% at bottom, transparent 50%, #fff 51%);
        mask-image: radial-gradient(110% 50% at bottom, transparent 50%, #fff 51%);
    }
    .homeContainer {
        margin-top: -55px !important;
        // margin: 25px;
    }
    .homeContent{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 35px;
        margin: 0 25px -15px 25px;
    }
    .homeProfileName{
        color: $primary-text-color;
        font-size: 22px !important;
        font-weight: 600;
    }
    .homeDesc {
        color: #000000;
        font-size: 14px;
        line-height: 18px;
    }
    .profileImg {
        height: 75px;
        width: 75px;
        border-radius: 50%;
        object-fit: cover;
    }
    .selectOptionSection {
        // margin-top: 25px;
        margin: 25px 15px -5px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .deliveryContainer{
        display: flex;
        justify-content: space-around;
        align-items: center;
        // background-color: $secondary-color;
        border-radius: 10px;
        height: 80px;
        margin: -15px 0 40px 0;
    }
    .pickupContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: $secondary-color;
        border-radius: 10px;
        height: 96px;
    }
    .deliveryBox {
        // background-color: #fff;
        border-radius: 12px;
        height: 95px;
        width: auto;
        margin: -15px -26px 0 0;
        filter: grayscale(80%);
    }
    .deliveryText {
        align-self: center;
        color: $primary-text-color;
        font-size: 18px;
        font-weight: 500;
        margin-left: -8px;
    }
    .pickupBox {
        border-radius: 12px;
        height: 95px;
        width: auto;
        margin: -15px -26px 0 0;
        // width: 41vw;
    }
    .pickupText {
        align-self: center;
        color: $primary-text-color;
        font-size: 18px;
        font-weight: 500;
    }
    .adImageBox {
        text-align: center;
        margin: 5px 0 -14px 0;
    }
    .adImage{
        border-radius: 20px;
        width: 100%;
    }
    .welcome{
        text-align: center;
        color: $primary-text-color;
        font-size: 25px;
        top: -15px;
    }
}

.advertiseScreen.show-scrollbar {
    scrollbar-width: thin; /* Firefox */
}

.advertiseScreen::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

.advertiseScreen::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
}

.advertiseScreen::-webkit-scrollbar-thumb {
    background-color: #cccccc; /* Thumb color */
    border-radius: 10px; /* Rounded corners */
}

.pickupBox {
    // background-color: #fff;
    border-radius: 12px;
    height: 125px;
    width: 175px;
}

.deliveryBox {
    // background-color: #fff;
    border-radius: 12px;
    height: 125px;
    width: 175px;
}

.selectOptionSection {
    margin: 15px 0 15px 0;
    display: flex;
    justify-content: space-evenly;
    text-align: center;
}

.deliveryContainer, .pickupContainer{
    cursor: pointer !important;
}

@media screen and ( min-width: 992px ) {
    .advertiseScreen{
        .homeContent{
            padding-top: 25px;
            padding-bottom: 40px;
            margin: 0 5px -5px 5px;
        }
        .homeProfileName{
            font-size: 26px !important;
        }
        .homeDesc {
            font-size: 18px;
        }
        .profileImg {
            height: 75px;
            width: 75px;
        }
        .selectOptionSection {
            margin: 35px 30px -50px 30px;
        }
        .deliveryContainer{
            height: 80px;
            margin: 15px 0 40px 0;
        }
        .pickupContainer {
            height: 96px;
        }
        .deliveryBox {
            height: 95px;
            margin: -15px -26px 0 0;
        }
        .deliveryText {
            font-size: 18px;
            margin-left: -8px;
        }
        .pickupBox {
            height: 95px;
            margin: -15px -26px 0 0;
        }
        .pickupText {
            font-size: 18px;
        }
        .adImageBox {
            margin: 5px 0 -14px 0;
        }
        .welcome{
            font-size: 25px;
            top: -15px;
        }
    }    
}

@media screen and ( min-width: 992px ) {
    .pickupBox {
        border-radius: 12px;
    }
    .welcome{
        margin-top: -50px !important;
    }
    .homeContent{
        margin-top: -10px;
        padding: 5px 25px 25px 25px;
    }
    .selectOptionSection {
        margin-top: 35px !important;
    }
}
