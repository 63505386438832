.product-item {
    padding: 10px 0;

    .imageBox {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .image {
        height: auto;
        width: 100%;
    }

    .productName {
        font-size: 14px;
        padding: 8px 0px 5px 10px;
        font-weight: 500;
        line-height: 16px;
        font-family: $secondary-font;
    }

    .productPrice {
        display: flex;
        align-items: center;
        padding-bottom: 7px;
    }

    .productMrp {
        font-size: 12px;
        padding: 0px 18px 0px 1px;
        font-weight: 500;
        font-family: $primary-font;
        color: gray;
        text-decoration: line-through;
    }

    .productOfferprice {
        font-size: 16px;
        padding: 0px 10px 0px 12px;
        color: #1DA1F2;
        font-weight: 500;
        font-family: $primary-font;
    }

    .productOff {
        font-size: 10px;
        font-weight: 500;
        border: 1.5px solid #1DA1F2;
        padding: 0px 7px 0px 7px;
        margin: 0px !important;
        color: $primary-color;
        border-radius: 6px;
        border-bottom-left-radius: 0px;
        height: auto;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .bestCouponBox {
        background-color: #F7F5FF;
        border-left: 7px solid $primary-color;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        margin-top: 7px;
        padding: 5px 0 5px 7px;
    }

    .bestCouponTag {
        font-size: 14px !important;
        font-family: $primary-font;
    }

    .bestCouponCode {
        font-size: 12px;
    }

    .bestCoupon {
        padding: 0 0 0 0;
    }

    .bestCouponOff {
        font-size: 10px;
        font-weight: 500;
        border: 1.5px solid #1DA1F2;
        padding: 0px 7px 0px 7px;
        margin: 0px !important;
        color: $primary-color;
        border-radius: 6px;
        border-bottom-left-radius: 0px;
        height: auto;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .veg-nonveg {
        width: 10%;
        height: 100%;
    }

    .addedBtn {
        background-color: $primary-color;
        font-family: $secondary-font;
        color: #fff;
        text-align: center;
        width: 50%;
        font-size: 14px;
        position: relative;
        cursor: pointer;
        border-radius: 50px;
        padding: 6px 12px 6px 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        .addedNumber {
            position: absolute;
            right: 2px;
            background-color: #fff;
            color: $primary-color;
            border-radius: 50%;
            padding: 4px 8px;
        }
    }

    .notAddedBtn {
        background-color: $primary-color;
        font-family: $secondary-font;
        color: #fff;
        text-align: center;
        width: 50%;
        font-size: 14px;
        position: relative;
        cursor: pointer;
        border-radius: 50px;
        padding: 4px 12px 4px 12px;
        display: flex;
        justify-content: center;

        .arrowBtn {
            position: absolute;
            right: 2px;
            color: #fff;
        }
    }

    .exhaustedBtn {
        background-color: #fff;
        font-family: $secondary-font;
        color: #1DA1F2;
        text-align: center;
        width: 50%;
        position: relative;
        cursor: pointer;
        border-radius: 50px;
        padding: 4px 10px 4px 10px;
        border: 1px solid #A8A8A8;

        .exhaustedText {
            font-size: 13px;
        }
    }
}

.exhausted {
    .imageBox {
        .image {
            filter: saturate(0.4);
        }
    }

    .productName,
    .productPrice,
    .productMrp,
    .productOff {
        color: #A8A8A8;
    }
}

@media screen and (min-width: 350px) {
    .product-item {
        padding: 10px 0;

        .productName {
            font-size: 16px;
            padding: 8px 0px 5px 10px;
        }

        // .productMrp{
        //     font-size: 18px;
        //     padding: 0px 18px 0px 12px;
        // }
        .addedBtn {
            width: 50%;
            font-size: 16px;
            padding: 8px 12px 8px 8px;

            .addedNumber {
                right: 2px;
                padding: 4px 8px;
            }
        }

        .notAddedBtn {
            font-size: 16px;
            padding: 4px 12px 4px 12px;

            .arrowBtn {
                right: 2px;
            }
        }

        .exhaustedBtn {
            width: 50%;
            font-size: 12px;
            padding: 6px 8px 6px 8px;

            .exhaustedText {
                font-size: 15px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .product-item {
        padding: 0px 0;
        height: 100%;
        width: 100%;

        .productBox {
            padding: 30px 0 10px 0;
        }

        .imageBox {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            justify-content: center;
        }

        .image {
            height: auto;
            width: 50%;
        }

        .veg-nonveg {
            width: 8%;
            height: 100%;
        }

        .productName {
            font-size: 16px;
            padding: 8px 0px 5px 10px;
            text-align: center;
        }

        .productOfferprice {
            font-size: 16px;
            padding: 0px 4px 0px 1px;
        }

        .productMrp {
            font-size: 12px;
            padding: 0px 8px 0px 1px;
        }

        .productOff {
            font-size: 10px;
            padding: 0px 5px 0px 5px;
            margin: 0px !important;
        }

        .addedBtn {
            width: 40%;
            font-size: 16px;
            padding: 4px 12px 4px 8px;

            .addedNumber {
                right: 2px;
                padding: 4px 8px;
            }
        }

        .notAddedBtn {
            width: 120% !important;
            font-size: 16px;
            padding: 2px 12px 2px 12px;

            .arrowBtn {
                right: 2px;
            }
        }

        .exhaustedBtn {
            width: 30%;
            font-size: 12px;
            padding: 6px 8px 6px 8px;

            .exhaustedText {
                font-size: 15px;
            }
        }

        .bestCoupon {
            padding: 0 14px 0 14px;
        }
    }
}